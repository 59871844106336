import * as THREE from 'three'
import { COLORS, FACES } from "../Global/materials";

const toRad = Math.PI / 180;

export default class ViewCube extends THREE.Object3D {
    constructor({
        size = 60,
        outline = true,
        bgColor = COLORS.white,
        hoverColor = COLORS.blue,
        outlineColor = COLORS.black
    }) {
        super();
        this._cubeSize = size;
        this._outline = outline;
        this._bgColor = bgColor;
        this._hoverColor = hoverColor;
        this._outlineColor = outlineColor;
        this._build();
    }
    _build() {
        const faceSize = this._cubeSize;
        const faceOffset = this._cubeSize / 2;

        /* faces: front, right, back, left, top, bottom */
        const cubeFaces = this._createCubeFaces(faceSize, faceOffset);
        for (let [i, props] of BOX_FACES.entries()) {
            cubeFaces.children[i].name = props.name;
            cubeFaces.children[i].material.color.setHex(this._bgColor);
            cubeFaces.children[i].material.map = props.map;
        }
        this.add(cubeFaces);

        if (this._outline) {
            this.add(this._createCubeOutline(this._cubeSize));
        }

        
    }
    _createFace(size, position, { axis = [0, 1, 0], angle = 0, name = "", matProps = {} } = {}) {
        if (!Array.isArray(size)) size = [size, size];
        const material = new THREE.MeshBasicMaterial({...matProps});
        const geometry = new THREE.PlaneGeometry(size[0], size[1]);
        const face = new THREE.Mesh(geometry, material);
        face.name = name;
        face.rotateOnAxis(new THREE.Vector3(...axis), angle * toRad);
        face.position.set(...position);
        return face;
    }
    _createCubeFaces(faceSize, offset) {
        const faces = new THREE.Object3D();
        faces.add(this._createFace(faceSize, [0, 0, offset], { axis: [0, 1, 0], angle: 0 }));
        faces.add(this._createFace(faceSize, [offset, 0, 0], { axis: [0, 1, 0], angle: 90 }));
        faces.add(this._createFace(faceSize, [0, 0, -offset], { axis: [0, 1, 0], angle: 180 }));
        faces.add(this._createFace(faceSize, [-offset, 0, 0], { axis: [0, 1, 0], angle: 270 }));
        faces.add(this._createFace(faceSize, [0, offset, 0], { axis: [1, 0, 0], angle: -90 }));
        faces.add(this._createFace(faceSize, [0, -offset, 0], { axis: [1, 0, 0], angle: 90 }));
        return faces;
    }
    _createCubeOutline(size) {
        const geometry = new THREE.BoxGeometry(size, size, size);
        const geo = new THREE.EdgesGeometry(geometry);
        const mat = new THREE.LineBasicMaterial({ color: this._outlineColor, linewidth: 2 });
        const wireframe = new THREE.LineSegments(geo, mat);
        return wireframe
    }
}

const FONT_SIZE = 70;
const FONT = "Inter, sans-serif";
const BOX_FACES = [
    {
        name: FACES.west,
        map: createTextSprite("W", { fontSize: FONT_SIZE, font: FONT })
    },
    {
        name: FACES.south,
        map: createTextSprite("S", { fontSize: FONT_SIZE, font: FONT })
    },
    {
        name: FACES.east,
        map: createTextSprite("E", { fontSize: FONT_SIZE, font: FONT })
    },
    {
        name: FACES.north,
        map: createTextSprite("N", { fontSize: FONT_SIZE, font: FONT, color: [255, 0, 0, 1.0] })
    },
    {
        name: FACES.top,
        map: createTextSprite("TOP", { fontSize: FONT_SIZE, font: FONT })
    },
    {
        name: FACES.bottom,
        map: createTextSprite("BOTTOM", { fontSize: FONT_SIZE, font: FONT })
    }
];

function createTextSprite(text, props) {
    const fontface = props.font || 'Helvetica';
    const fontsize = props.fontSize || 30;
    const width = props.width || 200;
    const height = props.height || 200;
    const bgColor = props.bgcolor ? props.bgColor.join(', ') : "255, 255, 255, 1.0";
    const fgColor = props.color ? props.color.join(', ') : "0, 0, 0, 1.0";
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    context.font = `bold ${fontsize}px ${fontface}`;
    context.fillStyle = `rgba(${bgColor})`;
    context.fillRect(0, 0, width, height);
    // get size data (height depends only on font size)
    const metrics = context.measureText(text);
    const textWidth = metrics.width;
    // text color
    context.fillStyle = `rgba(${fgColor})`;
    context.fillText(text, width / 2 - textWidth / 2, height / 2 + fontsize / 2 - 2);
    // canvas contents will be used for a texture
    const texture = new THREE.Texture(canvas)
    texture.minFilter = THREE.LinearFilter;
    texture.needsUpdate = true;
    return texture;
}