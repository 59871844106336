import axios from 'axios'
import { API_URL } from './CONSTANT'

const apiConfig = {
  headers: {
    Accept: 'application/json',
  },
  setAuthorization: (token) => {
    apiConfig.headers.Authorization = `Bearer ${token}`
  },
}

export const getDesign = async (token, designId) => {
  apiConfig.setAuthorization(token)

  try {
    const response = await axios.get(`${API_URL}/designer/${designId}`, apiConfig)
    return response
  } catch (error) {
    throw error
  }
}
export const downloadModel = async (token, designId) => {
  apiConfig.setAuthorization(token)

  try {
    const response = await axios.get(`${API_URL}/projects/download/${designId}`, apiConfig)
    return response
  } catch (error) {
    throw error
  }
}

export const updateProjectData = async (token, projectID, data) => {
  apiConfig.setAuthorization(token)

  const options = {
    method: 'POST',
    url: `${API_URL}/designer/${projectID}`,
    headers: apiConfig.headers,
    data,
  }

  try {
    const response = await axios.request(options)
    return response
  } catch (error) {
    throw error
  }
}

export const checkUser = async (token) => {
  apiConfig.setAuthorization(token)
  const options = {
    method: 'POST',
    url: `${API_URL}/me`,
    headers: apiConfig.headers,
    data: {},
  }

  try {
    const response = await axios.request(options)
    return response
  } catch (error) {
    throw error
  }
}

export const getPanelList = async (token) => {
  apiConfig.setAuthorization(token)

  try {
    const response = await axios.get(`${API_URL}/panels`, apiConfig)
    return response
  } catch (error) {
    throw error
  }
}

const getProjectImages = async (token, projectId) => {
  apiConfig.setAuthorization(token)

  const options = {
    method: 'POST',
    url: `${API_URL}/projects/images/${projectId}`,
    headers: apiConfig.headers,
    data: {},
}

  try {
    const response = await axios.request(options)
    return response
  } catch (error) {
    throw error
  }
}

export { getProjectImages }
