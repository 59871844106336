import * as THREE from 'three';
import ViewCubeControls from "../Compass/ViewCubeControls"
import { FACES } from '../Global/materials';
import CompassRingController from '../Compass/CompassRingControls';
import { translateYToZ } from '../Compass/Helpers';

export class CubeManager {
    constructor(camera, controls) {
        this.camera = camera;
        this.controls = controls;
        this.controlChange = this.controlChange.bind(this);
        this.cubeChange = this.cubeChange.bind(this);
        this.compassChange = this.compassChange.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.initialize();
        return this.getCubeSceneObjects();
    }

    initialize() {

        this.cubeRenderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
        this.cubeRenderer.setSize(140, 140);

        this.cubeScene = new THREE.Scene();
        this.cubeScene.background = null;
        this.cubeCamera = new THREE.PerspectiveCamera(50, 1, 0.1, 1000);

        this.cubeCamera.position.set(0, 0, 90);
        this.cubeCamera.lookAt(0, 0, 0);
        const quat = this.camera.quaternion.clone();
        const initialQuat = new THREE.Quaternion().setFromEuler(new THREE.Euler(Math.PI / 2, 0, -Math.PI / 2, 'YZX'));

        this.compassRing = new CompassRingController(this.cubeCamera, this.cubeRenderer.domElement);
        const compass = this.compassRing.getObject();
        this.cubeScene.add(compass);

        this.viewCube = new ViewCubeControls(this.cubeCamera, undefined, undefined, this.cubeRenderer.domElement);
        this.viewCube.setQuaternion(quat.invert().multiply(initialQuat));
        const cube = this.viewCube.getObject();
        this.cubeScene.add(cube);

        this.controls.addEventListener('change', this.controlChange);
        this.viewCube.addEventListener('angle-change', ({ quaternion, face }) => this.cubeChange(quaternion, face));
        this.compassRing.addEventListener('compass-angle-change', ({face}) => this.compassChange(face));        
    }

    controlChange() {
        const quat = this.camera.quaternion.clone();
        const initialQuat = new THREE.Quaternion().setFromEuler(new THREE.Euler(Math.PI / 2, 0, -Math.PI / 2, 'YZX'));
        this.viewCube.setQuaternion(quat.invert().multiply(initialQuat));

        const cubeRotation = this.viewCube.getObject().rotation;
        const z = Number(new THREE.Euler().setFromQuaternion(quat).z.toFixed(0));
        this.compassRing.getObject().rotation.z = -translateYToZ(cubeRotation.y, z);
    }

    cubeChange(quaternion, face) {
        this.controls.removeEventListener('change', this.controlChange);
        this.compassRing.removeEventListener('compass-angle-change', this.compassChange);

        const quat = quaternion.clone();
        const cube = this.viewCube.getObject();
        const pos = this.camera.position;
        const dist = new THREE.Vector3(pos.x, pos.y, 0).distanceTo(new THREE.Vector3());

        switch (face) {
            case FACES.west:
                this.camera.position.set(-dist, 0, pos.z);
                break;
            case FACES.east:
                this.camera.position.set(dist, 0, pos.z);
                break;
            case FACES.north:
                this.camera.position.set(0, dist, pos.z);
                break;
            case FACES.south:
                this.camera.position.set(0, -dist, pos.z);
                break;
            case FACES.top:
                this.camera.position.set(0, 0, pos.distanceTo(new THREE.Vector3()));
                break;
            default:
                break;
        }
        
        this.controls.update();

        const z = Number(new THREE.Euler().setFromQuaternion(quat).z.toFixed(0));
        this.compassRing.getObject().rotation.z = -translateYToZ(cube.rotation.y, z);

        this.controls.addEventListener('change', this.controlChange);
        this.compassRing.addEventListener('compass-angle-change', this.compassChange);
    }

    compassChange(face) {
        this.controls.removeEventListener('change', this.controlChange);
        this.viewCube.removeEventListener('cube-change', this.cubeChange);

        const pos = this.camera.position;
        const dist = new THREE.Vector3(pos.x, pos.y, 0).distanceTo(new THREE.Vector3());
        switch (face) {
            case FACES.west:
                this.camera.position.set(-dist, 0, pos.z);
                break;
            case FACES.east:
                this.camera.position.set(dist, 0, pos.z);
                break;
            case FACES.north:
                this.camera.position.set(0, dist, pos.z);
                break;
            case FACES.south:
                this.camera.position.set(0, -dist, pos.z);
                break;
            default:
                break;
        }
        this.controls.update();

        const quat = this.camera.quaternion.clone();
        const initialQuat = new THREE.Quaternion().setFromEuler(new THREE.Euler(Math.PI / 2, 0, -Math.PI / 2, 'YZX'));
        this.viewCube.setQuaternion(quat.invert().multiply(initialQuat));

        this.controls.addEventListener('change', this.controlChange);
        this.viewCube.addEventListener('cube-change', this.cubeChange);
    }

    handleResize() {
        this.cubeRenderer.domElement.style.top = (window.innerHeight - 232.8).toString() + 'px';
        this.cubeRenderer.render(this.cubeScene, this.cubeCamera);
    }

    getCubeSceneObjects() {

        return {
            cubeScene: this.cubeScene,
            cubeCamera: this.cubeCamera,
            cubeRenderer: this.cubeRenderer,
            viewCube: this.viewCube,
            compass: this.compassRing,
            handleResize: this.handleResize
        };

    }
}
