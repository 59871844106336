import * as THREE from 'three';
import { POLYGONS } from "../Global/variables";
import { getVertices } from './Helpers';
import { earClipping } from '../Global/functions';
import { sceneManager } from '../SetUps/SceneManager';

const OFFSET = 1/39.37;

export function panelInSegment(plane, panel, index) {
  const mesh = setbackSegment(index);
  const raycaster = new THREE.Raycaster();
  const normal = plane.normal.clone().normalize();

  const vertices = getVertices(panel);

  const projected = vertices.map(vertex => {      
    raycaster.set(vertex, normal);
    const intersects = raycaster.intersectObject(mesh, false);
    raycaster.set(vertex, normal.negate());
    const intersects2 = raycaster.intersectObject(mesh, false);
    if (intersects.length > 0 || intersects2.length > 0){
      return true;
    }
    return false
  })
  removeMesh(mesh);

  return projected.every(point => point) && !panelOverlap(raycaster, normal, vertices, index, panel) && !obstacleOverlap(raycaster, normal, index, panel);
}

const setbackSegment = (index) => {
  const vertices = POLYGONS[index].setBacks.edgesData.map((edge) => edge.point1);
  const mesh = earClipping(vertices);
  sceneManager.scene.add(mesh);
  return mesh;
}

const removeMesh = (mesh) => {
  sceneManager.scene.remove(mesh);
  mesh.geometry.dispose();
  mesh.material.dispose();
}

const panelOverlap = (raycaster, normal, vertices, index, panel) => {
  const existingPanels = POLYGONS[index].panels.map(panel => panel.panel.clone());
  const cut = POLYGONS[index].panels.map(pan => pan.panel).indexOf(panel);
  if (cut > -1) {
    existingPanels.splice(cut, 1);
  }  
  for (let i=0; i < vertices.length; i++) {
    const point = vertices[i].clone().add(normal.clone().multiplyScalar(-2));
    raycaster.set(point, normal);
    const intersects = raycaster.intersectObjects(existingPanels, false);
    if (intersects.length > 0) return true;
  }
  return false;
}

const obstacleOverlap = (raycaster, normal, index, panel) => {
  const obstructions = POLYGONS[index].obstructions;
  const shapes = obstructions.map((obs) => {return obs.mesh});
  const points = shapes.map((shape) => {return getVertices(shape)});
  for (let point of points) {
    for (let p=0; p < point.length; p++) {
      raycaster.set(point[p], normal.negate());
      const intersects = raycaster.intersectObject(panel, false);
      if (intersects.length > 0) return true;
    }
  }
  return false;
}

export function gridHelper(panel, panels){
  let closestPanel = null;
  let closest = Infinity;
  for (let pan of panels) {
    if (panel.position.distanceTo(pan.panel.position) < closest && panel.id !== pan.panel.id) {
      closestPanel = pan.panel;
      closest = panel.position.distanceTo(pan.panel.position);
    }
  }
  let panelGeo = panel.geometry.parameters;
  for (let pan of panels) {
    let panGeo = pan.panel.geometry.parameters;
    if (panel.id !== pan.panel.id) {
      if ((panelGeo.width > panelGeo.height && panGeo.width > panGeo.height && panel.position.distanceTo(pan.panel.position) < 1.5 * panelGeo.width) 
        || (panelGeo.height > panelGeo.width && panGeo.height > panGeo.width && panel.position.distanceTo(pan.panel.position) < 1.5 * panelGeo.height)) {
        closestPanel = pan.panel;
      }
    }
  }

  const panelWidth = closestPanel.geometry.parameters.width;
  const panelHeight = closestPanel.geometry.parameters.height;
  const cellWidth = panelWidth + OFFSET;
  const cellHeight = panelHeight + OFFSET;

  const worldFirst = new THREE.Vector3();
  const worldPanel = new THREE.Vector3();
  closestPanel.getWorldPosition(worldFirst);
  panel.getWorldPosition(worldPanel);
  const relPos = new THREE.Vector3().subVectors(worldPanel, worldFirst);
  const inverseRot = new THREE.Quaternion().copy(closestPanel.quaternion).invert();
  relPos.applyQuaternion(inverseRot);

  const xpos = relPos.x;
  const ypos = relPos.y;

  const row = Math.ceil(Math.abs(xpos) / panelWidth) + 2;
  const col = Math.ceil(Math.abs(ypos) / panelHeight) + 2;

  const material = new THREE.LineDashedMaterial({color: 0xffffff, transparent: true, opacity: 0.5, dashSize: 0.3, gapSize: 0.2, depthTest: false});
  const geometry = new THREE.BufferGeometry();
  const positions = [];

  const width = cellWidth * row;
  const height = cellHeight * col;
  
  let sign = xpos > 0 ? 1 : -1;
  for (let i = 0; i <= row; i++) {
    const x = sign * i * cellWidth;
    positions.push(x - (sign * cellWidth)/2, -height, 0);
    positions.push(x - (sign * cellWidth)/2, height, 0);
  }

  sign = ypos > 0 ? 1 : -1;
  for (let i = 0; i <= col; i++) {
    const y = sign * i * cellHeight;
    positions.push(-width, y - (sign * cellHeight)/2, 0);
    positions.push(width, y - (sign * cellHeight)/2, 0);
  }

  geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));

  const grid = new THREE.LineSegments(geometry, material);
  grid.renderOrder = 4;
  grid.name = "grid";
  grid.computeLineDistances();

  const position = snapToGrid(grid, panel, closestPanel, row);

  return [grid, position, closestPanel];
}

function snapToGrid(grid, panel, closestPanel, row) {
  const panelWidth = panel.geometry.parameters.width;
  const panelHeight = panel.geometry.parameters.height;
  const panelPosition = panel.position;
  const relPanelPosition = closestPanel.worldToLocal(panelPosition.clone());
  relPanelPosition.z = 0;

  const positionAttribute = grid.geometry.attributes.position;
  const vertexCount = positionAttribute.count;
  let xpositions = [];
  let ypositions = [];
  for (let i=0; i <= row*2; i = i+2) {
    const position = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
    xpositions.push(position.x);
  }
  for (let i=row*2+2; i < vertexCount; i=i+2) {
    const position = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
    ypositions.push(position.y);
  }

  let length = xpositions.length - 1;
  for (let i=0; i < length; i++) {
    xpositions.push((xpositions[i] + xpositions[i+1]) / 2);
  }
  length = ypositions.length - 1;
  for (let i=0; i < length; i++) {
    ypositions.push((ypositions[i] + ypositions[i+1]) / 2);
  }

  for (let xpos of xpositions) {
    if (Math.abs(relPanelPosition.x - xpos + panelWidth/2) < panelWidth/6) {
      relPanelPosition.x = xpos - panelWidth/2 - OFFSET/2;
      break;
    } else if (Math.abs(relPanelPosition.x - xpos - panelWidth/2) < panelWidth/6) {
      relPanelPosition.x = xpos + panelWidth/2 + OFFSET/2;
      break;
    }
  }

  for (let ypos of ypositions) {
    if (Math.abs(relPanelPosition.y - ypos + panelHeight/2) < panelHeight/6) {
      relPanelPosition.y = ypos - panelHeight/2 - OFFSET/2;
      break;
    } else if (Math.abs(relPanelPosition.y - ypos - panelHeight/2) < panelHeight/6) {
      relPanelPosition.y = ypos + panelHeight/2 + OFFSET/2;
      break;
    }
  }

  return relPanelPosition.applyMatrix4(closestPanel.matrixWorld);
}