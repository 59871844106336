import { themeState } from "../lib/atom";
import {Typography} from "antd";
import { useRecoilValue } from "recoil";

const Title = ({title}) => {
  const theme = useRecoilValue(themeState)
  return (
    <Typography.Title
      style={{ fontSize: "16px", fontWeight: "400" }}
      className={`${theme ? "text-light my-0" : "text-black-1 my-0"}`}
    >
      {title}
    </Typography.Title>
  )
}

export default Title;