import {POLYGONS} from "../../Global/variables";
import { createEdgesWireframe } from "../../Global/functions";
import {sceneManager} from "../../SetUps/SceneManager";
import {MATERIALS} from "../../Global/materials";
import * as THREE from "three";
import {createSegmentPlane} from "../../Global/functions";
import {removeMesh} from "./Functions";

export function addSetbackToEdge(setbacks, edgeIndex, inputValue, segmentIndex) {
    const plane = createSegmentPlane(segmentIndex);
    const segment = POLYGONS[segmentIndex].shape;
    const edge = setbacks[edgeIndex];
    const point1 = edge.point1;
    const point2 = edge.point2;
    const prevValue = edge.value * 0.0254;
    const setbackValue = (inputValue *  0.0254) - prevValue;

    const segNormal = plane.normal.clone().normalize();
    const normal = calculateNormal(point1, point2, segNormal);
    const midpoint = new THREE.Vector3().addVectors(point1, point2).divideScalar(2);
    const inwardNormal = ensureInwardNormal(normal, midpoint, segNormal, segment);

    const newVertex1 = new THREE.Vector3().addVectors(point1, inwardNormal.clone().multiplyScalar(setbackValue));
    const newVertex2 = new THREE.Vector3().addVectors(point2, inwardNormal.clone().multiplyScalar(setbackValue));

    adjustConnectedEdges(setbacks, edgeIndex, newVertex1, newVertex2,  edge);
    updateWireframe(setbacks, segmentIndex);
}

function calculateNormal(v1, v2, segNormal) {
    const edge = new THREE.Vector3().subVectors(v2, v1);
    const normal = new THREE.Vector3().crossVectors(edge, segNormal);
    return normal.normalize()
}

function ensureInwardNormal(normal, midpoint, direction, segment) {
    const raycaster = new THREE.Raycaster();
    const newPoint = new THREE.Vector3().addVectors(midpoint, normal);
    raycaster.set(newPoint.clone().add(direction).multiplyScalar(5), direction.negate());
    let intersect = raycaster.intersectObject(segment, false);
    if (intersect.length > 0) {
        return normal;
    } else {
        raycaster.set(newPoint.clone().add(direction.negate()).multiplyScalar(5), direction);
        intersect = raycaster.intersectObject(segment, false);
        if (intersect.length > 0) {
            return normal
        } else {
            return normal.negate();
        }
    }
}

function adjustConnectedEdges(setbacks, edgeIndex, newVertex1, newVertex2, edge) {
    const prevEdgeIndex = (edgeIndex - 1 + setbacks.length) % setbacks.length;
    const nextEdgeIndex = (edgeIndex + 1) % setbacks.length;

    const prevEdge = setbacks[prevEdgeIndex];
    const nextEdge = setbacks[nextEdgeIndex];

    if (prevEdge) {
        if (prevEdge.vertexId2 === edge.vertexId1 ) {
            let nearestPoint = getNearestPointOnLineSegment(prevEdge.point1, prevEdge.point2, newVertex1, newVertex2);
            newVertex1.copy(nearestPoint);
            prevEdge.point2 = newVertex1;
        }
         else if (prevEdge.vertexId1 === edge.vertexId2 ) {
            let nearestPoint = getNearestPointOnLineSegment(prevEdge.point2, prevEdge.point1, newVertex2, newVertex1);
            newVertex2.copy(nearestPoint);
            prevEdge.point1 = newVertex2;
        }
        else if (prevEdge.vertexId1 === edge.vertexId1) {
            let nearestPoint = getNearestPointOnLineSegment(prevEdge.point2, prevEdge.point1, newVertex1, newVertex2);
            newVertex1.copy(nearestPoint);
            prevEdge.point1 = newVertex1;
        }
        else if (prevEdge.vertexId2 === edge.vertexId2 ) {
            let nearestPoint = getNearestPointOnLineSegment(prevEdge.point1, prevEdge.point2, newVertex2, newVertex1);
            newVertex2.copy(nearestPoint);
            prevEdge.point2 = newVertex2;
        }
    }

    if (nextEdge) {
        if (nextEdge.vertexId1 === edge.vertexId2 ) {
            let nearestPoint = getNearestPointOnLineSegment(nextEdge.point2, nextEdge.point1, newVertex2, newVertex1);
            newVertex2.copy(nearestPoint);
            nextEdge.point1 = newVertex2;
        }
        else if (nextEdge.vertexId2 === edge.vertexId1 ) {
            let nearestPoint = getNearestPointOnLineSegment(nextEdge.point1, nextEdge.point2, newVertex1, newVertex2);
            newVertex1.copy(nearestPoint);
            nextEdge.point2 = newVertex1;
        }
        else if (nextEdge.vertexId1 === edge.vertexId1 ) {
            let nearestPoint = getNearestPointOnLineSegment(nextEdge.point2, nextEdge.point1, newVertex1, newVertex2);
            newVertex1.copy(nearestPoint);
            nextEdge.point1 = newVertex1;
        }
        else if (nextEdge.vertexId2 === edge.vertexId2 ) {
            let nearestPoint = getNearestPointOnLineSegment(nextEdge.point1, nextEdge.point2, newVertex2, newVertex1);
            newVertex2.copy(nearestPoint);
            nextEdge.point2 = newVertex2;
        }
    }

    edge.point1.copy(newVertex1);
    edge.point2.copy(newVertex2);
    setbacks[edgeIndex] = edge;
}

function getNearestPointOnLineSegment(lineStart, lineEnd, point1, point2) {
    const oEdge = lineStart;
    const dEdge = new THREE.Vector3().subVectors(lineEnd, lineStart).normalize();

    const oSetback = point2;
    const dSetback = new THREE.Vector3().subVectors(point1, point2).normalize();

    const diff = new THREE.Vector3().subVectors(oSetback, oEdge);

    const crossDir = new THREE.Vector3().crossVectors(dEdge, dSetback);
    const crossDirDiff = new THREE.Vector3().crossVectors(diff, dSetback);

    const t1 = crossDirDiff.length() / crossDir.length();

    const intersectionPoint = new THREE.Vector3().copy(dEdge).multiplyScalar(t1).add(oEdge);

    return intersectionPoint;
}

function updateWireframe(edges,index) {
    let edgePoints = [];
    for (let i = 0; i < edges.length; i++) {
        edgePoints.push(edges[i].point1);
        edgePoints.push(edges[i].point2);
    }
    let material = MATERIALS.setbackWireframe.clone();
    let edgeWireframe = createEdgesWireframe(edgePoints, material);

    let setback = POLYGONS[index].setBacks;
    if(setback.mesh){
        removeMesh(setback);
    }
   setback.mesh =edgeWireframe;
   sceneManager.scene.add(setback.mesh);
   setback.mesh.geometry.attributes.position.needsUpdate = true;
}





