import * as THREE from "three";
import { DrawingModes, ObstacleTypes, POLYGONS, VARS } from "../Global/variables";
import SegmentManager from "../Segments/SegmentManager";
import ObstacleManager from "../Obstacles/ObstacleManager";
import PanelManager from "../Panels/PanelManager";
import KeyEventListener from "./KeyEventListener";
import RulerManager from "../Ruler/RulerManager";
import { OBSTACLE } from "../Global/materials";
import { selection } from "./Helpers";
import { removeGrid } from "../Panels/PanelFunctions";

export class MouseEventHandlers {
  constructor(SceneManager, currentDrawingModeRef, obstacleTypeRef, updateDrawingMode,
    setPolygons, setObstacles, setPanels, setDeletePanels, setDeleteConfirmationVisible, selectedIndices, setSelectedIndices,
    setPolygonDetails, modelMeshes, solarPanel, setShowPanelSettings, mouseDown, setMouseDown, panelList) {
    this.mousePosition = new THREE.Vector2();
    this.mouseDown = mouseDown;
    this.setMouseDown = setMouseDown;
    this.mouseMoved = false;
    this.sceneManager = SceneManager;
    this.currentDrawingMode = currentDrawingModeRef.current;
    this.updateDrawingMode = updateDrawingMode;
    this.Mesh = modelMeshes;
    this.isDrawingStarted = false;
    this.shape = null;
    this.obstacleType = obstacleTypeRef.current;
    this.start = new THREE.Vector2();
    this.end = new THREE.Vector2();
    this.setPolygons = setPolygons;
    this.setObstacles = setObstacles;
    this.setPanels = setPanels;
    this.setDeletePanels = setDeletePanels;
    this.selectedIndices = selectedIndices;
    this.setSelectedIndices = setSelectedIndices;
    this.setPolygonDetails = setPolygonDetails;
    this.setShowPanelSettings = setShowPanelSettings;
    this.solarPanel = solarPanel;
    this.panelList = panelList
    this.panelTexture = null;
    const handleCompleteShape = () => {
      this.shape.completeShape(selectedIndices);
    }

    function undo() {
      this.shape.undo();
    }

    function redo() {
      this.shape.redo();
    }
    const cancelSegmentDrawing = () => {
      this.stopDrawing();
    }

    const cancelDrawing = () => {

      if (obstacleTypeRef.current === ObstacleTypes.RECTANGLE) {
        OBSTACLE.box.visible = false;
        this.stopDrawing();
      } else if (obstacleTypeRef.current === ObstacleTypes.CIRCLE) {
        OBSTACLE.circle.visible = false;
        this.stopDrawing();
      }
    }

    function removeRuler() {
      if (this.shape) {
        this.shape.removeRuler();
      }
      this.stopDrawing();
    }

    // Create a new key listener
    this.keyListener = VARS.keyListener;
    if (this.keyListener === undefined || this.keyListener == null) {
      this.keyListener = new KeyEventListener(
        handleCompleteShape.bind(this),
        this.currentDrawingMode,
        undo.bind(this),
        redo.bind(this),
        setDeleteConfirmationVisible.bind(this),
        this.selectedIndices,
        cancelDrawing.bind(this),
        removeRuler.bind(this),
        cancelSegmentDrawing.bind(this),
        this.updateDrawingMode,
        this.setDeletePanels
      );
    }
    else {
      this.keyListener.completeShape = handleCompleteShape.bind(this);
      this.keyListener.currentDrawingMode = this.currentDrawingMode;

      this.keyListener.undo = undo.bind(this);
      this.keyListener.redo = redo.bind(this);
      this.keyListener.selectedindices = this.selectedIndices;
      this.keyListener.canceldrawing = cancelDrawing.bind(this);
      this.keyListener.removeRuler = removeRuler.bind(this);
      this.keyListener.cancelSegmentDrawing = cancelSegmentDrawing.bind(this);
    }
    VARS.keyListener = this.keyListener;


  }


  handleMouseDown(event) {
    if (event.target === document.querySelector('canvas')) {
      this.setMouseDown(true);
      if (event.which === 1 || event.button === 0) {
        this.getMousePosition(event)

        let intersections = this.getIntersections()
        if (intersections.length > 0) {
          let point = intersections[0].point;
          switch (this.currentDrawingMode) {

            case DrawingModes.SEGMENT:
              if (!this.isDrawingStarted) {
                this.isDrawingStarted = true;
                if (this.shape == null) {
                  this.shape = new SegmentManager(
                    this.sceneManager.scene,
                    this.stopDrawing,
                    this.setPolygons,
                    this.selectedIndices,
                    this.setSelectedIndices,
                    this.setPolygonDetails
                  );
                }
              }

              this.shape.handleMouseDown(point)
              break;

            case DrawingModes.OBSTACLE:
              if (!this.isDrawingStarted) {
                this.isDrawingStarted = true;
                this.shape = new ObstacleManager(
                  this.sceneManager.scene,
                  this.sceneManager.camera,
                  this.obstacleType,
                  this.stopDrawing,
                  this.setObstacles,
                  this.selectedIndices,
                );
              }

              // Don't kn why it doesn't work with this.mousePosition
              this.start = this.getPoints(this.start);
              this.shape.handleMouseDown(this.start)
              break;

            case DrawingModes.RULER:
              if (!this.isDrawingStarted) {
                this.isDrawingStarted = true;
                this.shape = new RulerManager(
                  this.sceneManager.scene,
                  this.stopDrawing,
                );
              }
              this.shape.handleMouseDown(point)
              break;

            case DrawingModes.PANEL:
              this.shape.handleMouseDown();
              break;

            case DrawingModes.NONE:
              selection(this.selectedIndices, this.setSelectedIndices, this.setPolygonDetails, this.mousePosition, this.sceneManager, this.setShowPanelSettings);
              break;
          }
        }
      }
    }
  }

  handleMouseMove(event) {
    this.getMousePosition(event)
    let intersections = this.getIntersections()
    if (intersections.length > 0) {
      let point = intersections[0].point;
      switch (this.currentDrawingMode) {
        case DrawingModes.SEGMENT:
          if (this.shape == null) {
            this.shape = new SegmentManager(
              this.sceneManager.scene,
              this.stopDrawing,
              this.setPolygons,
              this.selectedIndices,
              this.setSelectedIndices,
              this.setPolygonDetails
            );
          }
          this.shape.handleMouseMove(point)
          break;
        case DrawingModes.OBSTACLE:
          if (this.isDrawingStarted) {
            this.end = this.getPoints(this.end)
            this.shape.handleMouseMove(this.end)
          }

          break;
        case DrawingModes.RULER:
          if (this.isDrawingStarted) {
            this.shape.handleMouseMove(point)
          }
          break;
        case DrawingModes.PANEL:
          if (!this.isDrawingStarted) {
            this.isDrawingStarted = true;
            this.shape = new PanelManager(
              this.sceneManager.scene,
              this.sceneManager.camera,
              this.selectedIndices.segmentIndex,
              this.solarPanel,
              this.setPanels,
              this.panelList,
              null,
              null, 
              false
            );
          }
          this.shape.handleMouseMove(this.mousePosition);
          break;
        case DrawingModes.NONE:
          // console.log(this.mouseDown, this.selectedIndices.panelIndex);
          if (this.mouseDown && this.selectedIndices.panelIndex !== null && this.selectedIndices.panelIndex.length > 0) {
            if (!this.isDrawingStarted) {
              this.isDrawingStarted = true;              
              const panel = POLYGONS[this.selectedIndices.segmentIndex].panels[this.selectedIndices.panelIndex[0]].panel;              
              this.shape = new PanelManager(
                this.sceneManager.scene,
                this.sceneManager.camera,
                this.selectedIndices.segmentIndex,
                this.solarPanel,
                this.setPanels,
                this.panelList,
                panel,
                panel.position.clone(),
                true
              );
            }
            this.mouseMoved = true;
            this.shape.handleMouseMove(this.mousePosition);
          }
          break;
        default:
          break;
      }

    }
  }

  handleMouseUp(event) {
    this.setMouseDown(false);
    this.mouseDown = false;
    if (this.mouseMoved && this.selectedIndices.panelIndex !== null && this.selectedIndices.panelIndex.length > 0) {
      this.shape.handleMouseUp();
      this.stopDrawing();
      removeGrid();
      this.mouseMoved = false;
    }
  }

  stopDrawing = () => {
    this.isDrawingStarted = false;
    this.updateDrawingMode(DrawingModes.NONE);
  }

  getMousePosition(event) {
    this.mousePosition.x = (event.clientX / window.innerWidth) * 2 - 1;
    const offsetY = event.clientY - 92.8;
    this.mousePosition.y = -(offsetY / this.sceneManager.height) * 2 + 1
  }

  getPoints(point) {
    // since it doesn't work with this.mousePosition we use this
    point.x = this.mousePosition.x;
    point.y = this.mousePosition.y;
    return point;
  }

  getIntersections() {
    let rayCaster = new THREE.Raycaster();
    rayCaster.setFromCamera(this.mousePosition, this.sceneManager.camera);
    return rayCaster.intersectObjects(this.Mesh, false);
  }



}