import React from 'react';
import { Image, Row, Col, Modal, Flex, Typography, Divider, Button, Tooltip } from 'antd';

import { getExifData } from '../helpers/ExifData';
import { FcNext, FcPrevious } from 'react-icons/fc';
const PhotoMetaData = ({
  openMetaModal,
  onClose,
  metaData,
  handlePreviousPhoto,
  handleNextPhoto,
  canGoNext,
  canGoPrev,
}) => {

  console.log('metaData:', metaData);
 

 
 

  // const { latitude, longitude } = convertDMSToDD(
  //   metaData?.exif?.GPS?.GPSLatitude,
  //   metaData?.exif?.GPS?.GPSLatitudeRef,
  //   metaData?.exif?.GPS?.GPSLongitude,
  //   metaData?.exif?.GPS?.GPSLongitudeRef,
  // );

  const exifData = getExifData(metaData);

  return (
    <Modal
      transitionName={false}
      key={metaData.name}
      title={
        <Typography.Title level={5} className="my-0" strong>
          {metaData.name}
        </Typography.Title>
      }
      width={1400}
      mask={false}
      footer={[
        <Flex justify="end" gap={'small'} key={'auto'}>
          <Tooltip title="Previous Image">
            <Button
              icon={<FcPrevious size={22} />}
              key="prev"
              onClick={handlePreviousPhoto}
              disabled={!canGoPrev}
            />
          </Tooltip>
          <Tooltip title="Next Image">
            <Button
              key="next"
              icon={<FcNext size={22} />}
              onClick={handleNextPhoto}
              disabled={!canGoNext}
            />
          </Tooltip>
        </Flex>,
      ]}
      centered
      open={openMetaModal}
      onCancel={onClose}
    >
      <Row gutter={[24, 24]} style={{ marginTop: '10px' }}>
        <Col xs={24} md={12}>
          <Image
            style={{ borderRadius: '10px', objectFit: 'cover', width: '100%' }}
            src={metaData?.image}
            // width={340}
            // height={300}
            alt="Image"
          />
        </Col>
        <Col xs={24} md={12}>
          <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                Make
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{exifData.cameraMake}</Flex>
          </Flex>
          <Divider style={{ height: 'auto', marginBlock: '5px' }} />
          <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                Model
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{exifData.cameraModel}</Flex>
          </Flex>
          <Divider style={{ height: 'auto', marginBlock: '5px' }} />
          <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                Mime Type
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{exifData.mimeType}</Flex>
          </Flex>
          <Divider style={{ height: 'auto', marginBlock: '5px' }} />
          <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                Width
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{exifData.imageWidth}</Flex>
          </Flex>
          <Divider style={{ height: 'auto', marginBlock: '5px' }} />
          <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                Height
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{exifData.height}</Flex>
          </Flex>
         <Divider style={{ height: 'auto', marginBlock: '5px' }} />
                    <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                Latitude
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{exifData.latitude}</Flex>
          </Flex>
          <Divider style={{ height: 'auto', marginBlock: '5px' }} />
          <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                Longitude
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{exifData.longitude}</Flex>
          </Flex>
          <Divider style={{ height: 'auto', marginBlock: '5px' }} />
          <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                DateTime
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{exifData.dateTimeOriginal}</Flex>
          </Flex>
          <Divider style={{ height: 'auto', marginBlock: '5px' }} />
          <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                FileSize
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{exifData.fileSize}</Flex>
          </Flex>
        </Col>
      </Row>




    </Modal>
  );
};

export default PhotoMetaData;