import {POLYGONS} from "../../Global/variables";
import {getPolyArea} from "../AreaPitchAzimu";
import {addSetbackToEdge} from "./Setbacks";
import {sceneManager} from "../../SetUps/SceneManager";

export function updateSetbackUI(segIndex, setInputValues, inputValue, edgeIndex) {
    POLYGONS[segIndex].setBacks.isSet = true;
    let setbacks = POLYGONS[segIndex].setBacks.edgesData;


    const updateSetback = (index) => {
        setInputValues(prevValues => ({
            ...prevValues,
            [index]: inputValue
        }));

        // const prevValue = setbacks[index].value *  0.0254;
        // let setbackValue = (inputValue *  0.0254) - prevValue;
        addSetbackToEdge(setbacks, index, inputValue, segIndex);
        setbacks[index].value = inputValue;
    };

    if (edgeIndex !== undefined) {
        updateSetback(edgeIndex);
    } else {
        setbacks.forEach((edge, index) => {
            updateSetback(index);
        });
    }
}

export function  updateAvaArea(index,setAvailableArea){
    let area = getPolyArea(POLYGONS[index].setBacks.edgesData).toFixed(2);
    POLYGONS[index].setBacks.avaArea = area;
    setAvailableArea(area);
}

export function deleteSetback(segmentIndex){
    let selectedSeg = POLYGONS[segmentIndex]
    let edges = selectedSeg.edges;
    let data =[]
    edges.forEach(edge => {
        const setback = {
            vertexId1: edge.vertexId1,
            vertexId2: edge.vertexId2,
            value: 0,
            point1: edge.point1.clone(),
            point2: edge.point2.clone()
        };
        data.push(setback);
    })
    let setback = selectedSeg.setBacks;
    removeMesh(setback);
    selectedSeg.setBacks.edgesData = data;
    selectedSeg.setBacks.isSet = false;
    // delete selectedSeg.setBacks.mesh;

}

export function removeMesh(setback){
    sceneManager.scene.remove(setback.mesh);
    setback.mesh.geometry.dispose();
    setback.mesh.material.dispose();
    setback.mesh = null;
}