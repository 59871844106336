import { Card, Form, Select, Flex, InputNumber, Row, Col, Switch, Button, Image, Tooltip } from "antd";
import {themeState} from "../lib/atom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FaBan } from "react-icons/fa6";
import { PiGridNineFill } from "react-icons/pi";
import React, { useState } from 'react';
import { deletePanelIndex, polygonDetailsSelector, selectedIndicesState, solarPanel } from "../ThreeJs/ReactStates/states";
import { DrawingModes } from "../ThreeJs/Global/variables";
import { DeleteOutlined, InfoCircleOutlined, PlusCircleFilled } from "@ant-design/icons";
import { deselectPanels, selectAllPanels } from "../ThreeJs/Panels/PanelFunctions";
import magic from "../assets/magic.svg";
import Icon from "./Icon";
import InfoModal from "./InfoModal";

const SolarPanelSettings = ({ updateDrawingMode, setShowPanelSettings, deleteConfirmation, panelList }) => {
	const theme = useRecoilState(themeState)[0];
	const details = useRecoilValue(polygonDetailsSelector);
	const [manual, setManual] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [panel, setSolarPanel] = useRecoilState(solarPanel);
	const [selectedIndices, setSelectedIndices] = useRecoilState(selectedIndicesState);
	const setDeletePanels = useSetRecoilState(deletePanelIndex);
	const {panelIndex} = selectedIndices;
	
	const options = panelList.map(panel => {
		return {
			value: panel.id,
			label: (
				<Flex gap='small' style={{alignItems: 'center'}}>
					<Image src={panel.image} width={30}/>
					{panel.name}
				</Flex>
			)
		}
	});

  const initialVals = {
    id: panel.id ?? null, 
    azimuth: panel.azimuth ?? details.azimu, 
    tilt: panel.tilt ?? details.pitch, 
    height: panel.height ?? 0, 
    racking: panel.racking ?? "Flush", 
    orientation: panel.orientation ?? "Portrait", 
    moduleSpacingCol: panel.moduleSpacingCol ?? 1, 
    moduleSpacingRow: panel.moduleSpacingRow ?? 1, 
    arraySpacingCol: panel.arraySpacingCol ?? 0, 
    arraySpacingRow: panel.arraySpacingRow ?? 0, 
    arrayLayoutCol: panel.arrayLayoutCol ?? 1, 
    arrayLayoutRow: panel.arrayLayoutRow ?? 1
  }

	const handleChange = (values) => {
    setSolarPanel(prevState => {return {...prevState, ...values}});
    updateDrawingMode(DrawingModes.NONE);
	}

	const handleToggle = (checked) => {
		if (checked) {
			setManual(true);
		} else {
			setManual(false);
			updateDrawingMode(DrawingModes.NONE);
		}
	}

	const handleAdd = () => {
		updateDrawingMode(DrawingModes.PANEL);
	}

	const handleSelect = () => {
		selectAllPanels(selectedIndices, setSelectedIndices, setShowPanelSettings);
	}

	const handleDeselect = () => {
		deselectPanels(panelIndex, setSelectedIndices, selectedIndices);
	}

	const handleDelete = () => {
		setDeletePanels(panelIndex);
		deleteConfirmation(true);
	}

  const label = (
    <Flex justify="space-between" style={{width: '100%'}}>
      <>Choose Module</>
      {panel.id !== null && <Tooltip title="Module Information">
        <InfoCircleOutlined 
					className="info"
          onClick={()=>setModalOpen(true)}
        />
      </Tooltip>}
    </Flex>
  )

	return (
		<div>
			<Card style={{ fontSize: '16px', maxWidth: '275px' }} bodyStyle={{ padding: 0, fontSize: '16px' }} className='segment__card'>
				<Form 
					layout="vertical" 
					style={{padding: '10px', height: '275px', overflow: 'auto', overflowX: 'hidden'}}
					className={` ${theme ? 'custom-scrollbar-dark' : 'custom-scrollbar'}`}
          initialValues={initialVals}
					onValuesChange={handleChange}
        >
					<Row justify='space-between' gutter={[16,10]} align='middle'>
						<Col span={24}>
							<Form.Item label={label} name="id">
								<Select 
									options={options}
									optionLabelProp="label"
									placement="bottomRight"
									size="large"
									variant={theme ? 'outlined' : 'filled'}
									dropdownStyle={theme ? { backgroundColor: 'black', color: 'white' } : {}}
									style={{height: 'auto', alignItems: 'center'}}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Azimuth(&deg;)" name="azimuth">
								<InputNumber disabled/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Tilt(&deg;)" name="tilt">
								<InputNumber disabled/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Height(in)" name="height">
								<InputNumber />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Racking" flex={2} name="racking">
								<Select 
									options={[{value: "Flush"},{value: "Tilted", disabled: true},{value: "Double Tilt", disabled: true},{value: "Ground", disabled: true}]}
									dropdownStyle={theme && {backgroundColor: 'black', color: "white"}}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Orientation" flex={3} name="orientation">
								<Select 
									options={[{value: "Landscape"},{value: "Portrait"}]}
									dropdownStyle={theme && {backgroundColor: 'black', color: "white"}}
								/>
							</Form.Item>
						</Col>
						<Col span={12}><p style={{marginTop: '11px'}}>Module Spacing (in)</p></Col>
						<Col span={6}>
							<Form.Item label="Col" name="moduleSpacingCol">
								<InputNumber min={0} disabled/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item label="Row" name="moduleSpacingRow">
								<InputNumber min={0} disabled/>
							</Form.Item>
						</Col>
						<Col span={12}>Array Spacing (in)</Col>
						<Col span={6}>
							<Form.Item name="arraySpacingCol">
								<InputNumber min={0} disabled/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="arraySpacingRow">
								<InputNumber min={0} disabled/>
							</Form.Item>
						</Col>
						<Col span={12}>Array Layout (modules)</Col>
						<Col span={6}>
							<Form.Item name="arrayLayoutCol">
								<InputNumber min={0} disabled/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="arrayLayoutRow">
								<InputNumber min={0} disabled/>
							</Form.Item>
						</Col>
						<Col span={16}>Manually Add Panels</Col>
						<Col span={6} offset={2}>
							<Switch onChange={(checked) => {handleToggle(checked)}} disabled={panel.id === null}/>
						</Col>
						<Col span={3}>
							<Tooltip title='Add new module'>
								<Button disabled={!manual} icon={<PlusCircleFilled />} onClick={handleAdd}/>
							</Tooltip>
						</Col>
						<Col span={3}>
							<Tooltip title='Select all modules'>
								<Button icon={<PiGridNineFill fontSize={20}/>} onClick={handleSelect}/>
							</Tooltip>
						</Col>
						<Col span={3}>
							<Tooltip title='Deselect all modules'>
								<Button disabled={panelIndex === null || panelIndex.length===0} icon={<FaBan />} onClick={handleDeselect}/>
							</Tooltip>
						</Col>
						<Col span={3}>
							<Tooltip title='Delete selected module(s)'>
								<Button danger disabled={panelIndex === null || panelIndex.length===0} icon={<DeleteOutlined />} onClick={handleDelete}/>
							</Tooltip>
						</Col>
						<Col span={8} />
					</Row>
				</Form>
				<Button block type="primary" icon={<Icon src={magic} />} iconPosition="end" className={`${!manual && "gray-to-white"}`} disabled style={{marginTop: '5px'}}>
					Auto Fill Modules
				</Button>
			</Card>
			{panel.id !== null && <InfoModal 
				open={modalOpen} 
				setOpen={setModalOpen} 
				panelInfo={panel}
				panelList={panelList}
			/>}
		</div>
	)
}

export default SolarPanelSettings;