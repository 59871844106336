import {polygonsState, selectedIndicesState} from "./states";
import {selector} from "recoil";
import {indexToLabel} from "../Segments/Labels";
export const addObstaclesSelector = selector({
    key: 'addObstaclesSelector',
    get: ({ get }) => {},
    set: ({ set, get }, { newObstacle, segmentIndex }) => {
        // const { segmentIndex } = get(selectedIndicesState);
        set(polygonsState, (oldPolygons) => {
            if (segmentIndex >= 0 && segmentIndex < oldPolygons.length) {
                const updatedPolygons = [...oldPolygons];
                const segment = {...updatedPolygons[segmentIndex]};

                if (typeof newObstacle === 'object' && newObstacle !== null) {
                    segment.obstructions = [...segment.obstructions, newObstacle];
                } else {
                    console.error('newObstacle is not an object');
                    return oldPolygons;
                }

                updatedPolygons[segmentIndex] = segment;
                return updatedPolygons;
            } else {
                console.error('Invalid segmentIndex');
                return oldPolygons;
            }
        });
    }
});
export const deleteObstacleSelector = selector({
    key: 'deleteObstacleSelector',
    get: ({ get }) => {},
    set: ({ set, get }, obsIndexToDelete) => {
        const { segmentIndex } = get(selectedIndicesState);
        set(polygonsState, (oldPolygons) => {
            if (segmentIndex >= 0 && segmentIndex < oldPolygons.length) {
                const updatedPolygons = [...oldPolygons];
                const segment = {...updatedPolygons[segmentIndex]};

                if (obsIndexToDelete >= 0 && obsIndexToDelete < segment.obstructions.length) {
                    segment.obstructions = segment.obstructions.filter((_, index) => index !== obsIndexToDelete);
                } else {
                    console.error('Invalid obstacle index to delete');
                    return oldPolygons;
                }

                updatedPolygons[segmentIndex] = segment;
                return updatedPolygons;
            } else {
                console.error('Invalid segmentIndex');
                return oldPolygons;
            }
        });
    }
});
export const updateObstacleHeightSelector = selector({
    key: 'updateObstacleHeightSelector',
    get: ({get}) => {},
    set: ({ set, get }, newHeight) => {
        const { segmentIndex, obstacleIndex } = get(selectedIndicesState);

        if (segmentIndex !== null && obstacleIndex !== null) {
            set(polygonsState, (oldPolygons) => {
                const updatedPolygons = oldPolygons.slice(); // Create a shallow copy of the polygons array

                if (segmentIndex >= 0 && segmentIndex < updatedPolygons.length) {
                    const segment = { ...updatedPolygons[segmentIndex] }; // Create a shallow copy of the segment object

                    if (obstacleIndex >= 0 && obstacleIndex < segment.obstructions.length) {
                        segment.obstructions = segment.obstructions.slice(); // Create a shallow copy of the obstructions array
                        segment.obstructions[obstacleIndex] = { ...segment.obstructions[obstacleIndex], height: newHeight }; // Update the height of the selected obstacle
                        updatedPolygons[segmentIndex] = segment;
                    } else {
                        console.error('Invalid obstacle index to update height');
                    }
                } else {
                    console.error('Invalid segmentIndex');
                }

                return updatedPolygons;
            });
        } else {
            console.error('Invalid segmentIndex or obstacleIndex');
        }
    },
});
export const addPanelSelector = selector({
    key: 'addPanelSelector',
    get: ({ get }) => {},
    set: ({ set, get }, { newPanel, segmentIndex }) => {
        // const { segmentIndex } = get(selectedIndicesState);
        set(polygonsState, (oldPolygons) => {
            if (segmentIndex >= 0 && segmentIndex < oldPolygons.length) {
                const updatedPolygons = [...oldPolygons];
                const segment = {...updatedPolygons[segmentIndex]};

                if (typeof newPanel === 'object' && newPanel !== null) {
                    segment.panels = [...segment.panels, newPanel];
                } else {
                    console.error('newPanel is not an object');
                    return oldPolygons;
                }

                updatedPolygons[segmentIndex] = segment;
                return updatedPolygons;
            } else {
                console.error('Invalid segmentIndex');
                return oldPolygons;
            }
        });
    }
});
export const deletePanelsSelector = selector({
    key: 'deletePanelsSelector',
    get: ({ get }) => {},
    set: ({ set, get }, panelIndices) => {
        const { segmentIndex } = get(selectedIndicesState);
        set(polygonsState, (oldPolygons) => {
            if (segmentIndex >= 0 && segmentIndex < oldPolygons.length) {
                const updatedPolygons = [...oldPolygons];
                const segment = {...updatedPolygons[segmentIndex]};

                for (let indexToDelete of panelIndices) {
                    if (indexToDelete < 0 || indexToDelete >= segment.panels.length) {
                        console.error('Invalid panel index to delete');
                        return oldPolygons;
                    }
                }
                segment.panels = segment.panels.filter((_, index) => !panelIndices.includes(index));

                updatedPolygons[segmentIndex] = segment;
                return updatedPolygons;
            } else {
                console.error('Invalid segmentIndex');
                return oldPolygons;
            }
        });
    }
});

export const deleteSegmentSelector = selector({
    key: 'deleteSegmentSelector',
    get: ({ get }) => {},
    set: ({ set, get }, indexToDelete) => {
        set(polygonsState, (oldPolygons) => {
            const updatedPolygons = [...oldPolygons];
            if (indexToDelete >= 0 && indexToDelete < updatedPolygons.length) {
                updatedPolygons.splice(indexToDelete, 1);
                return updatedPolygons.map((segment, index) => {
                    if (index >= indexToDelete) {
                        return {
                            ...segment,
                            label: indexToLabel(index),
                        };
                    } else {
                        return segment;
                    }
                });
            } else {
                console.error('Invalid index to delete');
            }

        });
    },
});
export const updatePolygonDetailsSelector = selector({

    key: 'updatePolygonDetailsSelector',
    get: ({get})=>{},
    set: ({ set, get },  updatedDetails ) => {
        const { segmentIndex } = get(selectedIndicesState);
        if (segmentIndex !== null && segmentIndex !== undefined) {
            set(polygonsState, (oldPolygons) => {
                const updatedPolygons = [...oldPolygons];

                    updatedPolygons[segmentIndex] = {
                        ...updatedPolygons[segmentIndex],
                        details: updatedDetails
                    };

                return updatedPolygons;
            });
        } else {
            console.error('Invalid segmentIndex');
        }
    },
});
export const updatePolygonSetbacksSelector = selector({
    key: 'updatePolygonSetbacksSelector',
    get: ({get})=>{},
    set: ({set, get}, {setbackAdded, segmentIndex}) => {
        if (segmentIndex !== null && segmentIndex !== undefined) {
            set(polygonsState, (oldPolygons) => {
                const updatedPolygons = [...oldPolygons];

                    updatedPolygons[segmentIndex] = {
                        ...updatedPolygons[segmentIndex],
                        setbacks: setbackAdded
                    };

                return updatedPolygons;
            });
        } else {
            console.error('Invalid segmentIndex');
        }
    },
});
