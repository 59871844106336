import { COLORS } from "../Global/materials";
import { POLYGONS } from "../Global/variables";
import { sceneManager } from "../SetUps/SceneManager";

export function selectAllPanels(selectedIndices, setSelectedIndices, setShowPanelSettings) {
  const {segmentIndex, panelIndex} = selectedIndices;
  const panels = POLYGONS[segmentIndex].panels;
  let indices = [];
  for (let i=0; i<panels.length; i++) {
    const panel = panels[i].panel;
    panel.children[0].material.setValues({opacity: 1, color: COLORS.yellow});
    setShowPanelSettings(true);
    indices.push(i);
  }
  setSelectedIndices({segmentIndex: segmentIndex, obstacleIndex: null, panelIndex: indices});
}

export function selectSinglePanel(panelIndexToAdd, setSelectedIndices, selectedIndices, setShowPanelSettings) {
  const {segmentIndex, panelIndex} = selectedIndices;
  const segment = POLYGONS[segmentIndex] ?? null;
  const panel = segment.panels[panelIndexToAdd].panel ?? null;
  if (panel) {
    if (panelIndex && panelIndex.includes(panelIndexToAdd)) {
      deselectPanels([panelIndexToAdd], setSelectedIndices, selectedIndices);
    } else {
      panel.children[0].material.setValues({opacity: 1, color: COLORS.yellow});
      setShowPanelSettings(true);
      if (!panelIndex) {
        setSelectedIndices({segmentIndex: segmentIndex, obstacleIndex: null, panelIndex: [panelIndexToAdd]});
      } else {
        setSelectedIndices({segmentIndex: segmentIndex, obstacleIndex: null, panelIndex: [...panelIndex, panelIndexToAdd]});
      }
    }
  }
}

export function deselectPanels(indices, setSelectedIndices, selectedIndices) {
  if (indices) {
    const {segmentIndex, obstacleIndex, panelIndex} = selectedIndices;
    const panels = POLYGONS[segmentIndex].panels ?? null;
    if (panels) {
      for (let index of indices) {
        panels[index].panel.children[0].material.setValues({opacity: 0, color: COLORS.red});
      }
      const updated = panelIndex.filter(val => !indices.includes(val));
      if (updated.length === 0) {
        setSelectedIndices({segmentIndex: segmentIndex, obstacleIndex: obstacleIndex, panelIndex: null});
      } else {
        setSelectedIndices({segmentIndex: segmentIndex, obstacleIndex: null, panelIndex: updated});
      }
    }
  }
}

export function deletePanels(segmentIndex, panelIndices) {
  let selectedSegment = POLYGONS[segmentIndex];
  let scene = sceneManager.scene;
  for (let index of panelIndices) {
    const selectedPanel = selectedSegment.panels[index].panel;
    scene.remove(selectedPanel);
    selectedPanel.geometry.dispose();
    selectedPanel.material.dispose();
  }
  selectedSegment.panels = selectedSegment.panels.filter((_, index) => !panelIndices.includes(index));
}

export function removeExtraPanels(polygons) {
  const allPanels = [];
  let found = false;
  let selected = [];
  sceneManager.scene.getObjectsByProperty("name", "panel", allPanels);
  for (let panel of allPanels) {
    found = false;
    const idToMatch = panel.id;
    selected.push(panel);
    for (let polygon of polygons) {
      const panelList = polygon.panels;
      for (let index of panelList) {
        if (index.panel_id === idToMatch) {
          found = true;
          selected.pop();
          break;
        }
      }
      if (found === true) break;
    }
  }

  for (let i=0; i < selected.length; i++) {
    sceneManager.scene.remove(selected[i]);
    selected[i].geometry.dispose();
    selected[i].material.dispose();
  }
}

export function removeGrid() {
  const grid = sceneManager.scene.getObjectByName("grid");
  if (grid) {
    grid.removeFromParent();
    grid.geometry.dispose();
    grid.material.dispose();
  }
}