
import {Button, Card, Flex, Input, Space, Typography} from 'antd'
import React, {useEffect, useState} from 'react'

import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    avaArea,
    deleteSetBacks,
    editSetBacks,
    isSetBackAdded,
    polygonDetailsSelector,
    selectedIndicesState, setBackInput
} from "../ThreeJs/ReactStates/states";

import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";

import {POLYGONS} from "../ThreeJs/Global/variables";
import {deleteSetback, updateAvaArea, updateSetbackUI} from "../ThreeJs/Segments/SetBacks/Functions";
import { updatePolygonSetbacksSelector } from '../ThreeJs/ReactStates/updates';


const SegmentDetail = () => {

    const details = useRecoilValue(polygonDetailsSelector)
    const [showSetBack,setShowSetBack] = useRecoilState(isSetBackAdded);
    const [editSetback,setEditSetback] = useRecoilState(editSetBacks);
    const [deleteSetBack,setDeleteSetBack] = useRecoilState(deleteSetBacks);
    const selectedIndices = useRecoilValue(selectedIndicesState);
    let setInputValues = useSetRecoilState(setBackInput);
    const addSetback = useSetRecoilState(updatePolygonSetbacksSelector);
    const [inputValue, setInputValue] = useState(0);
    const [availableArea, setAvailableArea] = useRecoilState(avaArea);

    useEffect(() => {
        let selectedSeg = POLYGONS[selectedIndices.segmentIndex];
        if(deleteSetBack && selectedSeg.setBacks.isSet){
            if(editSetBacks){
                setEditSetback(false);
                setInputValues(false);
                setShowSetBack(false);
            }
            deleteSetback(selectedIndices.segmentIndex)
            setDeleteSetBack(false);
            addSetback(false, selectedIndices.segmentIndex);
        }
    }, [deleteSetBack]);

    useEffect(() => {
        if (selectedIndices.segmentIndex !== null && showSetBack) {
            const initialInputValues = {};
            POLYGONS[selectedIndices.segmentIndex].setBacks.edgesData.forEach((edge, index) => {
                initialInputValues[index] = edge.value;
            });
            setInputValues(initialInputValues);
        }
    }, [editSetback]);

    useEffect(() => {
        if(selectedIndices.segmentIndex !=null){
            let isSetback = POLYGONS[selectedIndices.segmentIndex].setBacks.isSet;
            setShowSetBack(isSetback);
        }
    }, [selectedIndices.segmentIndex]);

    useEffect(() => {
        let isSetBack = POLYGONS[selectedIndices.segmentIndex].setBacks.isSet;
        // issue the showSetback is true before pressing button, the above useEffect wasn't working properly
        // solution : i used inputValue>0 to fix it.
        if (showSetBack  && inputValue > 0 && !isSetBack ) {
            let index = selectedIndices.segmentIndex;
            addSetback({setbackAdded: true, segmentIndex: selectedIndices.segmentIndex});
            updateSetbackUI(index,setInputValues,inputValue);
            updateAvaArea(index,setAvailableArea)
        }

    }, [showSetBack]);

    const handleAddSetback = () => {
        setShowSetBack(true);
        setEditSetback(true);
    };

    const handleDelete = () => {
        setDeleteSetBack(true);
    };

    const handleEdit = () => {
        setEditSetback(!editSetback);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setInputValue(value);
        }
    };


    return (
        <div>
    <Card style={{ fontSize:'16px'}} bodyStyle={{ padding: 0 ,fontSize: '16px'}}  className='segment__card'>
        <Flex justify='space-between' align='center' style={{ padding: '14px' }}>
            <Space.Compact direction='vertical'>
                <Typography.Text strong>
                    Pitch
                </Typography.Text>
                <Typography.Text style={{ fontSize:'12px' }}>
                    {details.pitch !== null ? `${details.pitch}°` : 'N/A'}
                </Typography.Text>
            </Space.Compact>
            <Space.Compact direction='vertical'>
                <Typography.Text strong>
                    Azimuth
                </Typography.Text>
                <Typography.Text style={{ fontSize:'12px' }}>
                    {details.azimu !== null ? `${details.azimu}°` : 'N/A'}
                </Typography.Text>
            </Space.Compact>
            <Space.Compact direction='vertical'>
                <Typography.Text strong>
                    Area
                </Typography.Text>
                <Typography.Text style={{ fontSize:'12px' }}>
                    {details.area !== null ? `${parseFloat(details.area).toFixed(2)} sqft` : 'N/A'}
                </Typography.Text>
            </Space.Compact>
        </Flex>

        <hr/>
        <Flex justify="space-between" align="center" style={{padding: '10px 14px'}}>
            <Typography.Text strong>
                FireSetbacks
            </Typography.Text>
            {showSetBack ? (
                <Space style={{paddingRight: '10px'}}>
                <EditOutlined className={'edit-icon'} style={{ padding:'3px' , cursor: 'pointer' ,color: editSetback ? 'blue' : '#A9A9A9'}} onClick={handleEdit} />
                <DeleteOutlined className={'delete-icon'} style={{padding:'3px', fontSize: '16px', cursor: 'pointer' }} onClick={handleDelete} />
            </Space>  ) : (
                <Space>
                    <Input
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        style={{ width: 45 }}
                    />
                    <Button onClick={handleAddSetback} className="add-button" icon={<PlusOutlined />} />
                </Space>
            )}
        </Flex>
        { showSetBack ? (
                <Flex justify="space-between" align="center" style={{ padding: '10px 14px' }}>
                    <Typography.Text strong>
                        Available Area
                    </Typography.Text>
                    <Typography.Text >
                        <div>
                            {`${availableArea !== '' ? `${availableArea}` : `${parseFloat(details.area).toFixed(2)} sqft`}`}
                        </div>

                    </Typography.Text>
                </Flex>
        ): ('')}

        {/*<Card style={{ fontSize:'16px'}}  title={titleDetails} bodyStyle={{ padding: 0 ,fontSize: '16px'}}  className='segment__card'>*/}

        {/*</Card>*/}
        {/*<Flex gap={'small'} className='panel-image'>*/}
        {/*    <Image src={black_svg} alt='black' />*/}
        {/*    <Flex justify='space-between' align='center' className='w-100'>*/}
        {/*        <Flex vertical gap={0}>*/}
        {/*            <Typography.Text strong className='text-black-1'>*/}
        {/*                Q.PEAK DUO*/}
        {/*            </Typography.Text>*/}
        {/*            <Typography.Text style={{ fontSize: '12px' }} className='text-black-1'>*/}
        {/*                MLG-10 300W*/}
        {/*            </Typography.Text>*/}
        {/*        </Flex>*/}
        {/*        <Image src={down_arrow} preview={false} width={20} alt='down_arrow' />*/}
        {/*    </Flex>*/}
        {/*</Flex>*/}
        {/*<Flex justify='space-between' align='center' style={{ marginTop:'30px' }}>*/}
        {/*    <Image preview={false} width={45} src={grid_add} />*/}
        {/*    <Image preview={false} width={45} src={grid_drag} />*/}
        {/*    <Image preview={false} width={45} src={grid_delete} />*/}
        {/*    <Image preview={false} width={45} src={grid_copy} />*/}
        {/*</Flex>*/}
    </Card>
        </div>
  )
}

export default SegmentDetail