import * as THREE from "three";
import {DrawingGlobals, VARS} from "../Global/variables";
import {createMeasurementLabels} from "./Labels";
import {GEOMETRIES, MATERIALS} from "../Global/materials";
import { createEdgesWireframe } from "../Global/functions";

export const createVertex = (position) => {
    const circle = new THREE.Mesh(GEOMETRIES.polygonVertex, MATERIALS.polygonVertex.clone());
    circle.position.copy(position);
    circle.name = "vertex";
    circle.renderOrder = 1;
    return circle;
};

export const createEdges = (coordinates, scene) => {
    const edgePoints = []; const edgeData =[];
    const setBackData =[];
    let vertices = DrawingGlobals.verticesMesh;
    for (let i = 0; i < coordinates.length; i++) {
        let index = (i + 1) % coordinates.length;
        const point1 = coordinates[i];
        const point2 = coordinates[index];

        edgePoints.push(point1);
        edgePoints.push(point2);
        let data = createMeasurementLabels(point1,point2,i,scene)

        data.vertexId1 = vertices[i].uuid;
        data.vertexId2 = vertices[index].uuid;
        const setback = {
            vertexId1: vertices[i].uuid,
            vertexId2:vertices[index].uuid,
            value: 0,
            point1: point1.clone(),
            point2: point2.clone()
        };
        setBackData.push(setback);
        edgeData.push(data);
    }
    VARS.measurementLabelCounter++;
    let material = MATERIALS.polygonWireframe.clone()
    let edgeWireframe = createEdgesWireframe(edgePoints,material)
    return {edgeData,edgeWireframe,setBackData};
};

// TODO: add in global functions -- DONE
// export const createEdgesWireframe = (edges,material) =>{
//     let geometry = new THREE.BufferGeometry().setFromPoints(edges)
//     let wireframe = new THREE.LineSegments( geometry,material );
//     wireframe.renderOrder = 3; wireframe.name="wireframe"
//     return wireframe;
// }


