import React, { useEffect, useRef, useState } from "react";
import { DesignerLayout } from "../layout/DesignerLayout";
import Segment from "../components/Segment";
import Main from "../ThreeJs/Main";
import LoadingScreen from "../components/LoadingScreen";
import { useParams } from "react-router-dom";
import { checkUser, getDesign, getPanelList, updateProjectData } from "../helpers/API";
import NotFound from "../components/errorsscreens/NotFound";
import { DEV_MOD, handleErrors } from "../helpers/CONSTANT";
import { DrawingModes, POLYGONS } from "../ThreeJs/Global/variables";
import DeleteConfirmation from "../components/DeleteConfirmation";
import { transformPolygonData } from "../ThreeJs/DataUtils/SavaData";
import { generateDxf } from "../ThreeJs/Files/Dxf/DxfGenerator";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { deleteObstacleIndex, deletePanelIndex, deleteSegmentIndex, selectedIndicesState, polygonsState, panelSettings } from "../ThreeJs/ReactStates/states";
import { deleteObstacle } from "../ThreeJs/Obstacles/ObstacleFunctions";
import { deleteSingleSegment } from "../ThreeJs/Segments/Delete";
import { deleteObstacleSelector, deletePanelsSelector, deleteSegmentSelector, updatePolygonDetailsSelector } from "../ThreeJs/ReactStates/updates";
import { removeSegmentDragControls, setSegmentDragControls } from "../ThreeJs/Segments/DragControls";
import { removeObstacleDragControls, setObstacleDragControls } from "../ThreeJs/Obstacles/DragControls";
import { deletePanels, removeExtraPanels, removeGrid } from "../ThreeJs/Panels/PanelFunctions";

const Designer = () => {

    const [currentDrawingMode, setCurrentDrawingMode] = useState(DrawingModes.NONE);
    const currentDrawingModeRef = useRef(DrawingModes.NONE);
    const [obstacleType, setObstacleType] = useState(null);
    const obstacleTypeRef = useRef(null);
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [deleteObsIndex, setDeleteObsIndex] = useRecoilState(deleteObstacleIndex);
    const [deleteSegIndex, setDeleteSegIndex] = useRecoilState(deleteSegmentIndex);
    const [deletePanIndex, setDeletePanIndex] = useRecoilState(deletePanelIndex);
    const [selectedIndices, setSelectedIndices] = useRecoilState(selectedIndicesState);
    const setShowPanelSettings = useSetRecoilState(panelSettings);
    const polygons = useRecoilValue(polygonsState);
    const updateObstacleList = useSetRecoilState(deleteObstacleSelector);
    const updateSegmentList = useSetRecoilState(deleteSegmentSelector);
    const updatePanelList = useSetRecoilState(deletePanelsSelector);
    const setPolygonDetails = useSetRecoilState(updatePolygonDetailsSelector);

    const [isLoading, setIsLoading] = useState(false);
    const [informationChecked, setInformationChecked] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [modelProgress, setModelProgress] = useState(0);
    const [currentDesign, setCurrentDesign] = useState(null);
    const [panelList, setPanelList] = useState(null);
    const { token, designID } = useParams();

    const updateDrawingMode = (newMode) => {
        setCurrentDrawingMode(newMode);
        currentDrawingModeRef.current = newMode;
    };

    const updateObstacleType = (type) => {
        setObstacleType(type);
        obstacleTypeRef.current = type
    }

    const handleDeleteCancellation = () => {
        setDeleteConfirmationVisible(false);
        setDeleteSegIndex(null);
        setDeleteObsIndex(null);
        setDeletePanIndex(null);
    };

    const handleDeleteConfirmation = () => {

        let segmentIndex = selectedIndices.segmentIndex;
        let obsIndex = selectedIndices.obstacleIndex;
        let panIndex = selectedIndices.panelIndex;

        if (deleteObsIndex != null) {
            deleteObstacle(segmentIndex, deleteObsIndex)
            updateObstacleList(deleteObsIndex);
            if (deleteObsIndex === obsIndex) {
                updateSelection(segmentIndex, null, panIndex)
                removeObstacleDragControls();
            }
            if (deleteObsIndex < obsIndex) {
                updateSelection(segmentIndex, obsIndex - 1, panIndex)
                let selectedObs = POLYGONS[segmentIndex].obstructions[obsIndex - 1].mesh;
                setObstacleDragControls(selectedObs)
                // selectObstacle(selectedIndices,obsIndex-1, setSelectedIndices)
            }
            setDeleteObsIndex(null);
        }
        else if (deleteSegIndex != null) {
            deleteSingleSegment(deleteSegIndex, segmentIndex)
            updateSegmentList(deleteSegIndex)
            if (segmentIndex === deleteSegIndex) {
                updateSelection(null, null, null)
                removeSegmentDragControls();
            } else if (deleteSegIndex < segmentIndex) {
                updateSelection(segmentIndex - 1, null, null)
                setSegmentDragControls(segmentIndex - 1, setPolygonDetails)
            }
            setDeleteSegIndex(null);
        }
        else if (deletePanIndex != null) {
            deletePanels(segmentIndex, deletePanIndex);
            updatePanelList(deletePanIndex);
            updateSelection(segmentIndex, obsIndex, null);
            setDeletePanIndex(null);            
        }
        setShowPanelSettings(false);
        updateDrawingMode(DrawingModes.NONE);
        setDeleteConfirmationVisible(false);
    };

    function updateSelection(segmentIndex, obstacleIndex, panelIndex) {
        setSelectedIndices({ segmentIndex: segmentIndex, obstacleIndex: obstacleIndex, panelIndex: panelIndex });
    }

    useEffect(() => {
        removeExtraPanels(polygons);
        removeGrid();
    }, [currentDrawingModeRef.current]);

    useEffect(() => {
        if (DEV_MOD) {
            setInformationChecked("found");
            setPanelList([{
                id: 0,
                image: "/assets/panels/panel14.jpg",
                name: "LG335",
                manufacturer: "LG",
                model: "LG335N1C-V5",
                notes: "",
                power_tolerance: 3,
                vmpp: 34.1,
                impp: 9.83,
                pmax: 335,
                voc: 9.83,
                isc: 41,
                max_series_fuse: 20,
                temp_pmax: -0.36,
                temp_voc: -0.27,
                temp_isc: 0.03,
                type: "Mono-crystalline",
                cell_quantity: "60",
                weight: "37.69",
                max_load_wind: "113",
                max_load_snow: "40",
                unit: "Metric",
                height: 1686,
                width: 1016
            }]);
        } else if (token && designID) {
            verifyUser();
            loadPanelList();
        } else {
            setInformationChecked("unauthenticated");
        }
    }, []);

    const verifyUser = () => {
        checkUser(token)
            .then((res) => {
                if (res.status === 200) {
                    setCurrentUser(res.data.data);
                    checkModelInfo();
                    
                }
            })
            .catch((err) => {
                setInformationChecked("unauthenticated");
            });
    };

    const checkModelInfo = () => {
        getDesign(token, designID)
            .then((res) => {
                if (res.status === 200) {
                    setCurrentDesign(res.data.data);
                    setInformationChecked("found");
                }
            })
            .catch((err) => {
                setCurrentDesign(null);
                setInformationChecked("not-found");
            });
    };

    const loadPanelList = () => {
        getPanelList(token)
            .then((res) => {
                if (res.status === 200) {
                    setPanelList(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUpdatePolygon = () => {
        let data = transformPolygonData(polygons)
        let polygonData = JSON.stringify(data);
        setIsLoading(true);

        updateProjectData(token, currentDesign.uuid, { "model_data": polygonData })
            .then((res) => {
                setIsLoading(false);
                return res;
            })
            .catch((error) => {
                handleErrors(error);
                setIsLoading(false);
            });
    };

    const handleGenerateDxf = () => {
        let data = transformPolygonData(polygons)
        generateDxf(data);
    }
    
    return (
        <>
            {informationChecked == null ? (
                <LoadingScreen informationChecked={informationChecked} />
            ) : informationChecked === "unauthenticated" ? (
                <NotFound type={"token"} />
            ) : informationChecked === "not-found" ? (
                <NotFound type={"design"} />
            ) : (
                modelProgress < 98 && (
                    <LoadingScreen informationChecked={informationChecked} />
                )
            )}
            {informationChecked === "found" && (
                <DesignerLayout
                    polygonLoading={isLoading}
                    polygonUpdate={handleUpdatePolygon}
                    currentProject={currentDesign}
                    type={modelProgress < 98 ? "hidden" : "visible"}
                    handleUpdatePolygon={handleUpdatePolygon}
                    handleGenerateDxf={handleGenerateDxf}
                    updateDrawingMode={updateDrawingMode}
                    currentDrawingMode={currentDrawingMode}
                >
                    <Segment
                        updateObstacleType={updateObstacleType}
                        updateDrawingMode={updateDrawingMode}
                        currentDrawingMode={currentDrawingMode}
                        setDeleteConfirmationVisible={setDeleteConfirmationVisible}
                        panelList={panelList}
                    />
                    <Main
                        setModelProgress={setModelProgress}
                        currentDesign={currentDesign}
                        currentDrawingModeRef={currentDrawingModeRef}
                        obstacleTypeRef={obstacleTypeRef}
                        updateDrawingMode={updateDrawingMode}
                        setDeleteConfirmationVisible={setDeleteConfirmationVisible}
                        panelList={panelList}
                    />
                    {deleteConfirmationVisible && (
                        <DeleteConfirmation
                            onConfirm={handleDeleteConfirmation}
                            onCancel={handleDeleteCancellation}
                        />
                    )}
                </DesignerLayout>
            )}
        </>
    );
};

export default Designer;