import * as THREE from 'three'
import { FACES, COLORS } from '../Global/materials';

const toRad = Math.PI / 180;
export default class CompassRing extends THREE.Object3D {
    constructor() {
        super();
        this._build();
    }
    _build() {
        const ringGeo = new THREE.RingGeometry(22, 23);
        const ringMat = new THREE.MeshBasicMaterial({color: COLORS.white});
        const ring = new THREE.Mesh(ringGeo, ringMat);
        this.add(ring);
        
        const arrows = this._createArrows();
        this.add(arrows);
    }
    _createArrows() {
        const arrows = new THREE.Object3D();
        arrows.add(this._createArrow(15, 5, 0xf64f4f, 0xd64f4f, FACES.south, [-23, 0, 0], 0));
        arrows.add(this._createArrow(15, 5, 0xb4b4b4, 0xadadad, FACES.north, [23, 0, 0], 180));
        arrows.add(this._createArrow(6, 4, 0xe2e2e2, 0xe0e0e0, FACES.east, [0, -23, 0], 90));
        arrows.add(this._createArrow(6, 4, 0xe2e2e2, 0xe0e0e0, FACES.west, [0, 23, 0], -90));
        return arrows;
    }
    _createArrow(length, width, color, hover, name, position, angle) {
        const shape = new THREE.Shape();
        shape.moveTo(-5, 0);
        shape.lineTo(length, width);
        shape.lineTo(length - 2, 0);
        shape.lineTo(length, -width);

        const geo = new THREE.ShapeGeometry(shape);
        const mat = new THREE.MeshBasicMaterial({color: color});
        const arrow = new THREE.Mesh(geo, mat);
        arrow.name = name;
        arrow.userData.color = color;
        arrow.userData.hover = hover;
        arrow.position.set(...position);
        arrow.rotateZ(angle * toRad)

        return arrow;
    }
}