import * as THREE from "three";
import {DXF_VARS} from "../../Global/variables";

export function projectFeatureTo2D(feature) {

    let projectedPoints = feature.polygon.map(point => {
        point = new THREE.Vector3(point.x, point.y, point.z)
        const x = point.dot(DXF_VARS.vectorU) ;
        const y = point.dot(DXF_VARS.vectorV);
        return new THREE.Vector2(x, y );
    });
    projectedPoints.push(projectedPoints[0]);

    return featPositioning(projectedPoints);
}

function featPositioning(feat) {
    let origin = DXF_VARS.origin;
    const translationVector = new THREE.Vector2(-origin.x, -origin.y);

    // new feat position with respect to my segment origin point
    const featPosition = feat.map(point => {
        const translatedPoint = point.clone().add(translationVector);
        return {x: translatedPoint.x, y: translatedPoint.y};

    });

    let lineAngle = DXF_VARS.rotationAngle;
    const rotatedFeat = featPosition.map(point => {
        const rotatedX = Math.cos(-lineAngle) * point.x - Math.sin(-lineAngle) * point.y;
        const rotatedY = Math.sin(-lineAngle) * point.x + Math.cos(-lineAngle) * point.y;
        return new THREE.Vector3(rotatedX, rotatedY, point.z);
    });
    if (DXF_VARS.isVerticalFlipped) {
        rotatedFeat.forEach(point => {
            point.y = -point.y;
        });
    }
    return rotatedFeat;
}