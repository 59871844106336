const TWOPI = 2 * Math.PI;

export function calculateYDelta(from, to, z) {
    if (to === Math.PI && from < 0) {
        to = - Math.PI;
    }
    if (z !== 0) {
        if (from < 0) {
            from = - Math.PI - from;
        } else {
            from = Math.PI - from;
        }
    }
    const direct = to - from;

    return direct;
}

export function calculateZDelta(from, to, side) {
    let direct = to - from;
    if (side !== 0) direct -= Math.PI;
    return direct
}
export function calculateDelta(from, to) {
    const direct = to - from;
    const altA = direct - TWOPI;
    const altB = direct + TWOPI;
    if (Math.abs(direct) > Math.abs(altA)) {
        return altA;
    }
    else if (Math.abs(direct) > Math.abs(altB)) {
        return altB;
    }
    return direct;
}
export function translateYToZ(y, z) {
    if (z !== 0) {
        if (y === 0) {
            return Math.PI;
        } else if (y < 0) {
            return Math.abs(- Math.PI - y);
        } else {
            return (Math.PI + y);
        }
    } else {
        if (y === 0) {
            return 0;
        } else if (y < 0) {
            return Math.abs(y);
        } else {
            return (2 * Math.PI) - y;
        }
    }
}