import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

const CollapseCard = ({ card, label }) => {
  const items = [
    {
      key: '1',
      label: label,
      children: card,
    },
  ]
  return (
    <Collapse
      className="segment__card"
      items={items}
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) =>
        <CaretRightOutlined style={{ color: '#ccc' }} rotate={isActive ? 90 : 0} />
      }
      expandIconPosition="end"
      style={{ marginTop: '10px' }}
    />
  )
}

export default CollapseCard;