import {projectSegmentTo2D} from "../Calculations/Polygon2dConversions";
import {projectFeatureTo2D} from "../Calculations/Feature2dConversions";
import {DXF_VARS, POLYGONS} from "../../Global/variables";
import {projectDormerTo2D} from "../Calculations/Dormer2dConversion";
import {getVerticesData} from "../../DataUtils/SavaData";


export function get2dData(segment, setbacks, obstruction, panels, segmentIndex) {

    let segment2dPoints = projectSegmentTo2D(segment);
    let setback2dPoints = null;
    let obstacle2dPoints = [];
    let panel2dPoints = [];
    let dormerData = [];

    if (setbacks.length > 0) {
        let setbackShape = [];
        setbacks.forEach((sb) => {
            setbackShape.push(sb.vertex);
        });
        setback2dPoints = projectFeatureTo2D({polygon: setbackShape});
    }

    if (obstruction.length > 0) {
        obstruction.forEach((obs) => {
            obstacle2dPoints.push({polygon:projectFeatureTo2D(obs)})
        })
    }

    if (panels.length > 0) {
        panels.forEach((panel) => {
            panel2dPoints.push({polygon:projectFeatureTo2D(panel)})
        })
    }

    if(DXF_VARS.dormerData.length > 0){
        DXF_VARS.dormerData[segmentIndex].forEach((childData)=> {
            let array = POLYGONS[childData].shape.geometry.attributes.position.array;
            let vertices = getVerticesData(array)
            dormerData.push(projectDormerTo2D(vertices));
        });
    }
    // console.log(`dormer data for segmnet ${segmentIndex} `)
    // console.log(dormerData)


    return {segment2dPoints, setback2dPoints, obstacle2dPoints, panel2dPoints, dormerData}

}
