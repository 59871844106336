import React, {useEffect, useState} from 'react';
import { Button } from 'antd';
import { EyeFilled, EyeInvisibleFilled} from '@ant-design/icons';
import { FaRegMap } from "react-icons/fa";
import {VARS} from "../ThreeJs/Global/variables";
import {sceneManager} from "../ThreeJs/SetUps/SceneManager";

const MapTileButton = () => {
    const [showMapTile, setShowMapTile] = useState(true);

    useEffect(() => {
        if(!showMapTile){
            sceneManager.scene.remove(VARS.mapTile);
        }else{
            sceneManager.scene.add(VARS.mapTile);
        }
    }, [showMapTile]);


    return (
        <Button
            type='primary'
            style={{
                padding: "2px",
                background: showMapTile ? '#1AAA65' : '#fff',
                color: showMapTile ? 'rgba(225,222,222,0.96)' : '#181818',
                borderRadius: 0,
                border:"none",
                display: 'flex',
                alignItems: 'center',
                fontWeight: showMapTile ? '300' : '500',


            }}
            className={"show-tile"}
            onClick={() => setShowMapTile(!showMapTile)}
        >
            <FaRegMap style={{ fontSize:"18px", margin: '0 5px' }}/>
            {showMapTile ? 'Show Map Tile' : 'Show Map Tile' }
            {showMapTile ? <EyeInvisibleFilled  style={{ color:'rgba(225,222,222,0.96)', fontSize:"18px", margin: '0 5px 0 15px', opacity:"0.7"}} /> : <EyeFilled style={{ margin:  '0 5px 0 15px',color:'rgba(199,195,195,0.96)', fontSize:"19px" }} />}
        </Button>
    );
};

export default MapTileButton;
