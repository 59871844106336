import * as THREE from 'three'
import {POLYGONS, VARS} from "../Global/variables";
import {sceneManager} from "../SetUps/SceneManager";
import {COLORS} from "../Global/materials";
import {removeObstacleDragControls, setObstacleDragControls} from "./DragControls";

export function deleteObstacle(segmentIndex, obstacleIndex) {
    let selectedSegment = POLYGONS[segmentIndex]
    let selectedObs = selectedSegment.obstructions[obstacleIndex];
    sceneManager.scene.remove(selectedObs.mesh);
    selectedObs.mesh.geometry.dispose();
    selectedObs.mesh.material.dispose();
    selectedSegment.obstructions.splice(obstacleIndex, 1);
    removeObstacleDragControls();
}

export function selectObstacle(selectedIndices,index, setSelectedIndices) {
    const { segmentIndex, obstacleIndex, panelIndex } = selectedIndices;
    let selectedObs = POLYGONS[segmentIndex].obstructions[index];
    if(obstacleIndex === index){
        setSelectedIndices({segmentIndex:segmentIndex, obstacleIndex: null, panelIndex: null});
        deselectObstacle(selectedObs);
    }else {
        setSelectedIndices({segmentIndex:segmentIndex,obstacleIndex:index,panelIndex:null})
        if(obstacleIndex !== null && POLYGONS[segmentIndex].obstructions.length >= obstacleIndex){
         deselectObstacle(POLYGONS[selectedIndices.segmentIndex].obstructions[obstacleIndex])
        }
        VARS.selectedObs = {originalColor:selectedObs.mesh.material.color.clone(), start: selectedObs.mesh.position.clone(), end: null }
        selectedObs.mesh.material.color.set(COLORS.yellow);
        setObstacleDragControls(selectedObs.mesh);
    }

}

export function deselectObstacle(selectedObs) {
    selectedObs.mesh.material.color.set(selectedObs.ogColor);
    removeObstacleDragControls();
    VARS.selectedObs.end = selectedObs.mesh.position.clone();
    resetObsCords(selectedObs);

}

function resetObsCords(selectedObs) {
    const diff = new THREE.Vector3().subVectors(VARS.selectedObs.end, VARS.selectedObs.start);
    for (let i=0; i<selectedObs.cords.length-1; i++) {
        let point = selectedObs.cords[i];
        selectedObs.cords[i] = new THREE.Vector3(point.x, point.y, point.z).add(diff);
    }
}