import { Col, Flex, Image, Modal, Row, Typography } from "antd";
import { useRecoilValue } from "recoil";
import { themeState } from "../lib/atom";

const InfoModal = ({open, setOpen, panelInfo, panelList}) => {
  const data = panelList.filter((panel) => panel.id === panelInfo.id)[0];
  const theme = useRecoilValue(themeState);

  const title = (
    <Typography.Title level={4}>
      Module Information
    </Typography.Title>
  )
  return (
    <Modal 
      open={open} 
      footer={null} 
      onCancel={()=>setOpen(false)} 
      centered
      width="765px"
      title={title}
    >
      <Flex vertical>
        <span>
          <b>Name: </b>
          <i>{data.name}</i>
        </span>
        <span>
          <b>Model: </b>
          <i>{data.model}</i>
        </span>
        <span>
          <b>Manufacturer: </b>
          <i>{data.manufacturer}</i>
        </span>
        <Flex>
          <Flex vertical style={{width: '50%'}}>
            <Typography.Text style={{fontWeight: '1000', marginBlock: '0.5em'}}>ELECTRICAL DATA</Typography.Text>
            <Row align='middle'>
              <Col span={12}>Pmax (STC)</Col>
              <Col offset={1} span={11}>{data.pmax}</Col>
            </Row>
            <Row align='middle'>
              <Col span={12}>Power Tolerance (+/-%)</Col>
              <Col offset={1} span={11}>{data.power_tolerance}</Col>
            </Row>
            <Row align='middle'>
              <Col span={12}>Vmpp (STC)</Col>
              <Col offset={1} span={11}>{data.vmpp}</Col>
            </Row>
            <Row align='middle'>
              <Col span={12}>Impp (STC)</Col>
              <Col offset={1} span={11}>{data.impp}</Col>
            </Row>
            <Row align='middle'>
              <Col span={12}>Voc (STC)</Col>
              <Col offset={1} span={11}>{data.voc}</Col>
            </Row>
            <Row align='middle'>
              <Col span={12}>Isc (STC)</Col>
              <Col offset={1} span={11}>{data.isc}</Col>
            </Row>
            <Row align='middle'>
              <Col span={12}>Maximum Series Fuse</Col>
              <Col offset={1} span={11}>{data.max_series_fuse}</Col>
            </Row>
            <Row align='middle'>
              <Col span={12}>Temp. Coeff. of Pmax(%)</Col>
              <Col offset={1} span={11}>{data.temp_pmax}</Col>
            </Row>
            <Row align='middle'>
              <Col span={12}>Temp. Coeff. of Voc(%/C)</Col>
              <Col offset={1} span={11}>{data.temp_voc}</Col>
            </Row>
            <Row align='middle'>
              <Col span={12}>Temp. Coeff. of Isc(%/C)</Col>
              <Col offset={1} span={11}>{data.temp_isc}</Col>
            </Row>
            <Typography.Text style={{fontWeight: '1000', marginBlock: '0.5em'}}>OPERATING CONDITION & MECHANICAL DATA</Typography.Text>
            <Row align='middle'>
              <Col span={12}>Type</Col>
              <Col offset={1} span={11}>{data.type}</Col>
            </Row>
            <Row align='middle'>
              <Col span={12}>Cell Quantity</Col>
              <Col offset={1} span={11}>{data.cell_quantity}</Col>
            </Row>
            <Row align='middle'>
              <Col span={12}>Weight (lb)</Col>
              <Col offset={1} span={11}>{data.weight}</Col>
            </Row>
            <Row align='middle'>
              <Col span={12}>Max. Load Wind (psf)</Col>
              <Col offset={1} span={11}>{data.max_load_wind}</Col>
            </Row>
            <Row align='middle'>
              <Col span={12}>Max. Load Snow (psf)</Col>
              <Col offset={1} span={11}>{data.max_load_snow}</Col>
            </Row>
          </Flex>
          <Flex style={{width: '25%', alignItems: 'center', alignSelf: 'center'}} vertical>
            <Image src={data.image} width={'100%'}/>
            <div>Width: {data.width} {`${data.unit === "Imperial" ? 'in' : 'mm'}`}</div>
          </Flex>
          <div style={{alignSelf: 'center', marginLeft: '5px'}}>Height: {data.height} {`${data.unit === "Imperial" ? 'in' : 'mm'}`}</div>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default InfoModal;