import * as THREE from 'three';
import { DXF_VARS, POLYGONS } from "../Global/variables";
import { get2dData } from "../Files/Pdf/Generate2dPoints";
import { isPolygonInsidePolygon } from "../Files/Calculations/DormerIdentification";

export const transformPolygonData = (polygonState) => {
    let polygons = POLYGONS;
    let transformedData = [];
    DXF_VARS.dormerData.length = 0;

    for (let i = 0; i < polygons.length; i++) {
        let array = polygons[i].shape.geometry.attributes.position.array;
        let vertices = getVerticesData(array);
        findDormers(vertices, i);
    }
    traverseAndClean();


    for (let i = 0; i < polygons.length; i++) {
        let facet = polygons[i];
        let array = [];
        polygons[i].edges.map(edge => {
            array.push(edge.point1.x);
            array.push(edge.point1.y);
            array.push(edge.point1.z);
        });
        let polygonShape = getVerticesData(array);

        let setbacks = [];
        if (facet.setBacks.isSet) {
            facet.setBacks.edgesData.map(edge => {
                let point = edge.point1;
                let value = edge.value;
                setbacks.push({"vertex": { x: point.x, y: point.y, z: point.z }, "value": value});
            });
        };

        let obstructions = [];
        facet.obstructions.map(obs => {
            // let position = obs.height.position;
            // TODO: get updated position after drag
            let obstructionShape = obs.cords.map(vector => ({
                x: vector.x,
                y: vector.y,
                z: vector.z,
            }));
            let type = obs.type;
            let height = obs.height;
            obstructions.push({ "polygon": obstructionShape, "type": type, "height": height });
        });

        let panels = [];
        let covered_area = 0;
        facet.panels.map(panel => {
            let tempGeo = new THREE.BufferGeometry();
            tempGeo.copy(panel.panel.geometry);
            tempGeo.applyMatrix4(panel.panel.matrixWorld);
            let array = tempGeo.attributes.position.array;
            let panelShape = getVerticesData(array);
            let p = panelShape.splice(2,1);
            panelShape.push(p[0]);
            let position = panel.panel.position;

            let panelInfo = polygonState[i].panels.filter(pan => pan.panel_id === panel.panel.id)[0];
            panels.push({"polygon": panelShape, "position": position, "id": panelInfo.id,
                "height": panelInfo.height, "orientation": panelInfo.orientation, "area": panel.area
            });
            covered_area += panel.area;
        })

        let detail = facet.details;
        let avaArea = facet.setBacks.avaArea;
        let name = facet.label.element.innerHTML;
        let data2d = get2dData(polygonShape, setbacks, obstructions, panels, i);


        transformedData.push({
            "label": name, "polygon": polygonShape, "setbacks": setbacks, "obstruction": obstructions,
            "panels": panels, "rotated_polygon": data2d.segment2dPoints, "rotated_setbacks": data2d.setback2dPoints,
            "rotated_obstruction": data2d.obstacle2dPoints, "rotated_panels": data2d.panel2dPoints,
            "detail": detail, "area_details": {"total": detail.area, "available": avaArea, "covered": covered_area, 
            "remaining": avaArea-covered_area}, "dormerData": data2d.dormerData
        });
    }
    return transformedData;
}

// Finds all dormers inside the parent segments
function findDormers(parentPoints, currentIndex) {
    DXF_VARS.dormerData.push([]); // register the parent by its index first.
    for (let i = 0; i < POLYGONS.length; i++) {

        if (currentIndex !== i) {
            let polygonVertices = POLYGONS[i].shape.geometry.attributes.position.array;
            let dormerPoints = [];
            for (let j = 0; j < polygonVertices.length; j += 3) {
                dormerPoints.push({ x: polygonVertices[j], y: polygonVertices[j + 1], z: polygonVertices[j + 2] });
            }
            let isInside = isPolygonInsidePolygon(dormerPoints, parentPoints);
            if (isInside) {
                DXF_VARS.dormerData[currentIndex].push(i);

            }
        }
    }
}

export function getVerticesData(polygonVertices) {
    let polygonShape = [];
    for (let j = 0; j < polygonVertices.length; j += 3) {
        let vertex = {
            x: polygonVertices[j],
            y: polygonVertices[j + 1],
            z: polygonVertices[j + 2]
        };
        polygonShape.push(vertex);
    }
    return polygonShape;
}

function traverseAndClean() {
    let visited = new Set();
    let elementMap = new Map();

    function traverse(index) {
        if (visited.has(index)) return;
        visited.add(index);

        let currentArray = DXF_VARS.dormerData[index];
        let newArray = [];

        for (let element of currentArray) {
            if (!elementMap.has(element)) {
                elementMap.set(element, index);
                traverse(element);
                newArray.push(element);
            }
        }

        DXF_VARS.dormerData[index] = newArray;
    }

    function removeFromOtherArrays() {
        for (let [element, index] of elementMap.entries()) {
            for (let i = 0; i < DXF_VARS.dormerData.length; i++) {
                if (i !== index) {
                    DXF_VARS.dormerData[i] = DXF_VARS.dormerData[i].filter(e => e !== element);
                }
            }
        }
    }

    for (let i = 0; i < DXF_VARS.dormerData.length; i++) {
        if (!visited.has(i)) {
            traverse(i);
        }
    }

    removeFromOtherArrays();
    return DXF_VARS.dormerData;
}




