import {LABELS, POLYGONS, VERTICES} from "../Global/variables";
import {COLORS} from "../Global/materials";
import {deselectObstacle} from "../Obstacles/ObstacleFunctions";
import {removeSegmentDragControls, setSegmentDragControls} from "./DragControls";
import { deselectPanels } from "../Panels/PanelFunctions";


export function selectSegment(selectedIndices,index,setSelectedIndices,setPolygonDetails){
    let prev = selectedIndices.segmentIndex;
    if(prev != null && selectedIndices.obstacleIndex != null){
        let selectedObs = POLYGONS[prev].obstructions[selectedIndices.obstacleIndex];
        deselectObstacle(selectedObs);
    }
    if(prev === index && prev != null){
        deselectSegment(index);
        deselectPanels(selectedIndices.panelIndex, setSelectedIndices, selectedIndices);
        setSelectedIndices({segmentIndex: null, obstacleIndex: null, panelIndex: null});
    }else if( setPolygonDetails !=null){
        prev !== null && deselectSegment(prev);
        segmentHighlight(index, { opacity: 0.2, color: COLORS.red, transparent: true, labelVisible: true, vertexOpacity: 0.6 });
        setSelectedIndices({segmentIndex: index, obstacleIndex: null, panelIndex: null});
        setSegmentDragControls(index,setPolygonDetails)
    }

}

export function deselectSegment(index){
    segmentHighlight(index, { opacity: 0.05, color: COLORS.white, transparent: true,labelVisible: false, vertexOpacity: 0 });
    removeSegmentDragControls();
}

export function toggleSegmentLabels( showLabels) {
    for (let i = 0; i < POLYGONS.length; i++) {
        let segment = POLYGONS[i];
        for (let key of segment.edges) {
            LABELS.measurements[key.id].visible = showLabels;
        }
    }
}

export function showSelectedSegmentLabel(index){
    let segment = POLYGONS[index];
    for (let key of segment.edges) {
        LABELS.measurements[key.id].visible = true;
    }
}
function segmentHighlight(index, options) {
    const segment = POLYGONS[index];
    segment.shape.material.opacity = options.opacity || 0.2;
    segment.shape.material.color.setHex(options.color || 0xffffff);
    segment.shape.material.transparent = options.transparent || false;

    for (let key of segment.edges) {
        LABELS.measurements[key.id].visible = options.labelVisible || false;
    }

    VERTICES[index].forEach(element => {
        element.material.opacity = options.vertexOpacity || 0;
    });
}
