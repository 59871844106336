import {CSS2DObject} from "three/examples/jsm/renderers/CSS2DRenderer";
import { Vector3 } from 'three';
import {DrawingGlobals, LABELS, POLYGONS} from "./variables";
import {LINES, MATERIALS} from "./materials";
import * as THREE from "three";
import Earcut from "earcut";
import {sceneManager} from "../SetUps/SceneManager";

export function createLabelDiv(className,label){
    const Div = document.createElement('div');
    Div.className =className;
    let container = new CSS2DObject(Div);
    container.element.innerText = label;
    return container;
}
export function getGeoCenter(geo){
    geo.geometry.computeBoundingBox();
    geo.geometry.computeVertexNormals();
    geo.geometry.normalizeNormals();
    let center = new Vector3();
    geo.geometry.boundingBox.getCenter( center );
    return center;
}

//TODO use in segment manager
export function cleanUp(){
    DrawingGlobals.intersectionPoints =[];
    DrawingGlobals.lineMeshes =[];
    DrawingGlobals.verticesMesh =[];
    // HISTORY = [];
}

export function removeLineMeshes(scene){
    LINES.referenceLine.visible = false;
    for (let line in DrawingGlobals.lineMeshes) {
        scene.remove(DrawingGlobals.lineMeshes[line]);
        DrawingGlobals.lineMeshes[line].geometry.dispose();
    }
    LABELS.angle.visible=false;
}

export function updateSetBacksLabelsPosition(camera,halfWidth,halfHeight,label,edge){
    const pos = new THREE.Vector3();
    pos.lerpVectors(edge.point1, edge.point2, 0.5);
    pos.project(camera);

    if (label) {
        pos.x=  (pos.x * halfWidth) + halfWidth;
        pos.y=  - (pos.y * halfHeight) + halfHeight;
        label.style.top =`${pos.y}px`;
        label.style.left =`${pos.x}px`;
    }
}

export function earClipping(vertices){
    const verticesFlat = vertices.reduce((acc, vertex) => acc.concat([vertex.x, vertex.y, vertex.z]), []);
    const earCutData = Earcut(verticesFlat, null, 3);
    const indices = new Uint32Array(earCutData);
    const geo = new THREE.BufferGeometry();
    geo.setIndex(new THREE.BufferAttribute(indices, 1));
    geo.setAttribute('position', new THREE.BufferAttribute(new Float32Array(verticesFlat), 3));
    return new THREE.Mesh(geo, MATERIALS.polygon.clone());
}

export const createEdgesWireframe = (edges,material) =>{
    let geometry = new THREE.BufferGeometry().setFromPoints(edges)
    let wireframe = new THREE.LineSegments( geometry,material );
    wireframe.renderOrder = 3; wireframe.name="wireframe"
    return wireframe;
}

// TODO remove and replace the obstacle Manager func -- DONE
export function createSegmentPlane(index){
    let plane = new THREE.Plane();
    let gp = POLYGONS[index].shape.geometry.attributes.position;
    let p1 = new THREE.Vector3().fromBufferAttribute(gp, 0);
    let p2 = new THREE.Vector3().fromBufferAttribute(gp, 1);
    let p3 = new THREE.Vector3().fromBufferAttribute(gp, 2);
    plane.setFromCoplanarPoints(p1, p2, p3);
    // plane.translate(new THREE.Vector3(0.1,0.1,0.1));
    return plane;
}

// -- for testing purposes
function showPlane(p1,p2,p3){
    // console.log("here");
    const planeGeometry = new THREE.BufferGeometry();
    const vertices = new Float32Array([
        p1.x, p1.y, p1.z,
        p2.x, p2.y, p2.z,
        p3.x, p3.y, p3.z,
        p1.x, p1.y, p1.z, // Repeating the first point to close the plane
    ]);
    planeGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

    // Create the plane mesh
    const planeMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00, side: THREE.DoubleSide, wireframe: true });
    let planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);
    sceneManager.scene.add(planeMesh);
}