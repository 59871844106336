import {LABELS, POLYGONS, VERTICES} from "../Global/variables";
import {sceneManager} from "../SetUps/SceneManager";
import {updateSegmentLabel} from "./Labels";
import {removeSegmentDragControls} from "./DragControls";

export function deleteSingleSegment(index) {
    let segment = POLYGONS[index];
    let scene = sceneManager.scene;
    removeShape(segment, index, scene);
    removeLabels(segment, scene);
    removeObstacles(segment, scene);
    removePanels(segment, scene);
    if (segment.setBacks.isSet) {
        removeSetbacks(segment, scene);
    }
    POLYGONS.splice(index, 1);
    updateSegmentLabel();
}

export function deleteAllSegments(index) {
    removeSegmentDragControls();
    for (let i = 0; i <= POLYGONS.length - 1; i++) {
        let segment = POLYGONS[i];
        let scene = sceneManager.scene;
        removeShape(segment, index, scene)
        removeLabels(segment, scene)
        removeObstacles(segment, scene)
        removePanels(segment, scene);
        if (segment.setBacks.isSet) {
            removeSetbacks(segment, scene);
        }
    }
    POLYGONS.length = 0;
    VERTICES.length = 0;
    LABELS.measurements.length = 0;
}

function removeShape(segment, index, scene) {
    let shape = segment.shape;

    for (let child in shape.children) {
        if (shape.children[child].name === "vertex") {
            scene.remove(shape.children[child]);
            shape.children[child].geometry.dispose();
        }
    }
    scene.remove(shape);
    shape.geometry.dispose();
    VERTICES.splice(index, 1); // Remove the array at the specified index from VERTICES

}

function removeLabels(segment, scene) {
    let segmentLabel = segment.label
    scene.remove(segmentLabel);
    let measurementLabels = LABELS.measurements;
    for (let key of segment.edges) {
        scene.remove(measurementLabels[key.id]);
        delete measurementLabels[key.id];
    }
}

function removeObstacles(segment, scene) {
    let obstructions = segment.obstructions
    for (let obs in obstructions) {
        let shape = obstructions[obs].mesh
        scene.remove(shape)
        shape.geometry.dispose();
        shape.material.dispose();
    }
}

export function removePanels(segment, scene) {
    let panels = segment.panels;
    for (let panel of panels) {
        let shape = panel.panel;
        scene.remove(shape);
        shape.geometry.dispose();
        shape.material.dispose();
    }
    segment.panels = [];
}

function removeSetbacks(segment, scene) {
    let setbacks = segment.setBacks.mesh;
    scene.remove(setbacks);
    setbacks.geometry.dispose();
    setbacks.material.dispose();
    setbacks = null;
}